import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
interface Props {
  url: string;
  name: string;
}

export default function Thumbnail(props: Props) {
  const downloadImage = () => {
    const a = document.createElement("a");
    a.href = props.url;
    a.download = "downloaded-image.jpg"; // You can set the desired filename here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <Box
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#f0f0f0",
        position: 'relative',
      }}
    >
      <img
        src={props.url}
        alt="photo"
        style={{
          height: "165px",
          margin: "10px",

          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "13px 16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "16px",
            letterSpacing: "0em",
            color: "#3C3C43",
          }}
        >
          {props.name}
        </Typography>
        <IconButton
          onClick={downloadImage}
          sx={{
            background: "rgb(32 125 185 / 71%)",
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            "&:hover": {
              color: "rgb(32 125 185 / 71%)", // Hover color
            },
          }}
        >
          <FileDownloadOutlinedIcon
            sx={{
              color: "#fff",
              fontSize: "16px",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

import { Game } from "app/containers/Games/types";
import { OrderDirections, Pagination } from "app/containers/types";

export type GamesSelectState = {
  games: Game[];
  pagination: Pagination;
  isLoading: boolean;
};

export const GamesSelectState: GamesSelectState = {
  games: [],
  isLoading: false,
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 30,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  }
};

export type ContainerState = GamesSelectState;
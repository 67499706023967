import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Typography } from "@mui/material";
import { selectCurrentTab, selectOrderedList } from "app/containers/Results/redux/selector";
import { actions } from "app/containers/Results/redux/slice";
import { OrderDirections } from "app/containers/types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { TableRow } from "./row";
import {
  gridTemplateColumns,
  TableHeaderDefinition,
  TableHeaders,
} from "./headers";
type Props = {
  isMobile: boolean;
};

export default function Table({ isMobile }: Props) {
  const list = useSelector(selectOrderedList);
  const searchParams = useSearchParams();
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction") as OrderDirections;
  const currentTab = useSelector(selectCurrentTab)
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { height } = useWindowDimensions();

  const handleCurrentResult = (id?: string) => {
    dispatch(actions.setCurrentResult(id));
  };

  useEffect(() => {
    if (orderBy && orderDirection) {
      dispatch(actions.onSort({ name: orderBy, direction: orderDirection }));
    }
  }, [orderBy, orderDirection, dispatch]);

  if (list.length === 0) {
    return (
      <Box
        sx={{
          paddingX: "12px",
          marginTop: "32px",
          marginBottom: "10px",
          justifyContent: "center",
          alignItems: "center",
          height: height - 420,
          overflowY: "scroll",
        }}
      >
        <Typography noWrap style={{ textAlign: "center" }}>
          {t("RESULTS.LABELS.NO_DATA_YET")}
        </Typography>
      </Box>
    );
  }
  if(isMobile && currentTab != 'groups') {return null}
  return (
    <Box
      sx={{
        overflowX: "auto", // Enable horizontal scrolling
        whiteSpace: "nowrap", // Prevent wrapping
        width: "100%", // Ensure the container takes full width
      }}
    >
      <Box
        sx={{
          paddingX: "12px",
          marginTop: "32px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          minHeight: "400px",
          minWidth: "max-content",
        }}
      >
        {/* Table Header for Desktop */}
        {!isMobile && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: gridTemplateColumns, // Dynamically set grid columns based on number of headers
              fontWeight: "bold",
              borderRadius: "8px",
              textAlign: "center",
              color: "#fff",
              backgroundColor: "rgb(226, 67, 152)",
            }}
          >
            {TableHeaders.map((header) => (
              <div
                style={{
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
                key={header.id}
              >
                {t(`TABLES.${header.label}`)}
              </div> // Dynamically render header labels
            ))}
            <div
              style={{
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
              key={"actions"}
            >
              {t(`TABLES.STATIONS`)}
            </div>
          </Box>
        )}

        {/* Table Rows */}
        {list.map((row, index) => (
          <TableRow
            key={row._id}
            row={row}
            index={index}
            isMobile={isMobile}
            onClick={() => handleCurrentResult(row._id)}
          />
        ))}
      </Box>
    </Box>
  );
}

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Tooltip,
  Typography,
} from "@mui/material";
import InputField from "app/components/InputField";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import CustomButton from "app/components/Buttons/CustomButton";
import { MODES } from "utils/constants";
import { useTranslation } from "react-i18next";
import { selectTicket } from "app/containers/Tickets/redux/selector";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "app/containers/Tickets/redux/slice";
import { selectDirection } from "styles/theme/slice";
import Counter from "../Counter";

export default function CustomizationForm({ mode }: { mode: MODES }) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const ticket = useSelector(selectTicket);
  const dispatch = useDispatch();
  const openCustomizationPageInNewTab = (id: string) => {
    window.open(`/tickets/customization/${id}?hl=${localStorage.getItem("i18nextLng")}`, "_blank");
  };

  const isCustomizationAvailableButtonEnabled = (mode !== MODES.NEW && ticket?.isCustomizationAvailable.value)

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#F3F6F8",
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "20px",
          letterSpacing: "0em",
          color: "#207DB9",
        }}
      >
        {t("TICKETS.FORM.CUSTOMIZATIONS")}
      </Typography>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          gap: "10px",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            px: "16px",
            mt: "24px",
          }}
        >
          <FormControlLabel
            sx={{
              margin: 0,
              
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "left",
            }}

            label={
             
                <Typography
                  sx={{
                    
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "14px",
                    letterSpacing: "0em",
                  }}
                >
                  {t("TICKETS.FORM.CUSTOMIZE")}
                </Typography>

            }
            control={
              <Checkbox
                value={ticket?.isCustomizationAvailable.value}
                checked={ticket?.isCustomizationAvailable.value}
                name="isCustomizationAvailable"
                disabled={mode === MODES.VIEW}
                onChange={(e) => {
                  dispatch(
                    actions.setFormValues({
                      key: "isCustomizationAvailable",
                      value: e.target.checked,
                    })
                  );
                }}
                size="small"
                sx={{
                  color: "#207DB9",
                  "&.Mui-checked": {
                    color: "#207DB9",
                  },
                }}
              />
            }
          />
        </Box>
        <CustomButton
          variant="outlined"
          startIcon={
            <SettingsIcon
              sx={{
                color: isCustomizationAvailableButtonEnabled ? "#E24399" : "#ccc",
                fontSize: "20px",
              }}
            />
          }
          tooltip={!isCustomizationAvailableButtonEnabled  ? t("TICKETS.FORM.SHOW_CUSTOMIZATIONS_ENABLE") : ''}
          label={t("TICKETS.FORM.SHOW_CUSTOMIZATIONS")}
          onClick={() => openCustomizationPageInNewTab(ticket?._id.value || "")}
          sx={{
            height: "44px",
            padding: "14px 24px 14px 24px",
            borderRadius: "8px",
            border: "2px solid #E24399",
            color: "#E24399",
            
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "14px",
            letterSpacing: "0em",
            textAlign: "center",
            "&:hover": {
              border: "2px solid #E24399 !important",
              opacity: 0.8,
            },
            backgroundColor: "#FFFFFF",
          }}
          disabled={!isCustomizationAvailableButtonEnabled}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "14px 16px",
          mt: "24px",
          width: "fit-content",
        }}
      >
      <Box
        sx={{
       
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
          }}
        >
          {t("TICKETS.FORM.MAXIMUM_NUMBER_CUSTOMIZED_QUESTIONS")}
        </Typography>
        <Counter 
          disabled={mode === MODES.VIEW}
          maxCount={99}
          value={parseInt(ticket?.maxQuestions.value)}
          onChange={(n)=>{
              dispatch(
                  actions.setFormValues({
                      key: "maxQuestions",
                      value: n,
                  })
              );
          }}
        />
      </Box>
    {ticket?.maxQuestions.error &&  <FormHelperText  error> {t(ticket?.maxQuestions.error || '')}</FormHelperText>}
    </Box>
    </Box>
  );
}

import { Customer } from "app/containers/Customers/types";
import { Game } from "app/containers/Games/types";
import { SingleMultiSelectAnswer } from "app/containers/Questions/types";
import {
  FormElement,
  Metadata,
  OrderDirections,
  Pagination,
} from "app/containers/types";
import { User } from "app/types";
import { ASSET_TYPE, QUESTION_TYPES, TAsset } from "utils/constants";

export type TicketForm = {
  _id: FormElement;
  customerId: FormElement;
  organizationId:FormElement
  customer: any;
  gameId: FormElement;
  game: any;
  noOfDevices: FormElement;
  noOfDevicesPlayed: FormElement
  startDate: FormElement;
  createdAt: FormElement;
  endDate: FormElement;
  isDatesApplicable: FormElement;
  isCustomizationAvailable: FormElement;
  maxQuestions: FormElement;
  testPassword: FormElement;
  password: FormElement
};
export type TicketCustomizeForm = {
  _id: FormElement;
  gameName: FormElement;
  ticket: any;
  game: any;
  asset: TAsset;
  questions: CustomizeQuestion[];
};

export type CustomizeQuestion = {
  _id: string;
  type: QUESTION_TYPES.MULTI_SELECT;
  body: string;
  asset: TAsset;
  answers: Array<SingleMultiSelectAnswer>;
};

export type TicketsState = {
  customizeForm: TicketCustomizeForm;
  form: TicketForm;
  tickets: Ticket[];
  pagination: Pagination;
  isLoading: boolean;
  customizeFormQuestion: CustomizeQuestion;
  isUnsaved:boolean
  isSharing: boolean;
};

export const TicketsState: TicketsState = {
  tickets: [],
  isLoading: false,
  isSharing: false,
  form: {
    _id: {
      value: "",
      error: "",
    },
    customerId: {
      value: "",
      error: "",
    },
    organizationId: {
      value: "",
      error: "",
    } ,
    customer: null,
    gameId: {
      value: "",
      error: "",
    },
    game: null,
    noOfDevices: {
      value: 0,
      error: "",
    },
    noOfDevicesPlayed: {
      value: 0,
      error: "",
    },
    startDate: {
      value: "",
      error: "",
    },
    createdAt: {
      value: "",
      error: "",
    },
    endDate: {
      value: "",
      error: "",
    },
    isDatesApplicable: {
      value: false,
      error: "",
    },
    isCustomizationAvailable: {
      value: false,
      error: "",
    },
    maxQuestions: {
      value: 0,
      error: "",
    },
    testPassword: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    }
  },
  customizeFormQuestion: {
    _id: "",
    type: QUESTION_TYPES.MULTI_SELECT,
    body: "",
    asset: {
      _id: "",
      type: ASSET_TYPE.IMAGE,
      mimeType: "",
      fileName: "",
      filePath: "",
      url: "",
    },
    answers: [],
  },

  customizeForm: {
    _id: { value: "", error: "" },
    gameName: { value: "", error: "" },
    ticket: null,
    game: null,
    asset: {
      _id: "",
      type: ASSET_TYPE.IMAGE,
      mimeType: "",
      fileName: "",
      filePath: "",
      url: "",
    },
    questions: [],
  },
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 40,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  isUnsaved:false
};

export interface TicketListResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Ticket[];
}

interface Customizations {
  isCustomizationAvailable: boolean;
  maxQuestions: number;
}

export interface Ticket {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  customer?: Customer;
  game?: Game;
  creator?: User;
  organization?: string;
  noOfDevices: number;
  noOfDevicesPlayed?: number;
  startDate: string;
  endDate: string;
  isDatesApplicable: boolean;
  customizations: Customizations;
  testPassword: string;
  password: string;
  customerId?: string;
  gameId?: string;
}



export interface TicketCustomize {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  customer?: Customer;
  game?: Game;
  noOfDevices: number;
  startDate: string;
  endDate: string;
  isDatesApplicable: boolean;
  customizations: Customizations;
  testPassword: string;
  customerId?: string;
  gameId?: string;
}

export type ContainerState = TicketsState;

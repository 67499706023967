import { ThemeKeyType } from "./types";
import toastService from "utils/toast";
import { translate } from "utils/constants";
import dayjs from "dayjs";
import parsePhoneNumber from "libphonenumber-js";
import { DateTime } from "luxon";
import { SingleMultiSelectAnswer } from "app/containers/Questions/types";
import {SingleMultiData } from 'app/containers/Questions/components/Details/components/QuestionTypes/constants';
/* istanbul ignore next line */
export const isSystemDark = window?.matchMedia
  ? window.matchMedia("(prefers-color-scheme: dark)")?.matches
  : undefined;

export function saveTheme(theme: ThemeKeyType) {
  window.localStorage && localStorage.setItem("selectedTheme", theme);
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKeyType | null {
  return window.localStorage
    ? (localStorage.getItem("selectedTheme") as ThemeKeyType) || null
    : null;
}

export function scrollBottom(element) {
  element && element.scroll({ top: element.scrollHeight, behavior: "smooth" });
}

export function safeParseJSON(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}
export function validateAndFormatIsraeliMobileNumber(number = "") {
  // Remove any non-numeric characters from the number
  if (!number) {
    number = "";
  }

  if (number.startsWith("+")) {
    var numericNumber = number.replace(/\+\D/g, "").replace("-","");

    // Check if the number starts with "05" and has 9 digits
    if (/^\+972\d{9}$/.test(numericNumber)) {
      // Format the number as "+972-500000023"
      var formattedNumber = numericNumber.replace(/(\+\d{3})(\d{9})/, "$1-$2");

      return {
        isValid: true,
        formattedNumber: formattedNumber,
        numericNumber,
      };
    } else {
      return {
        isValid: false,
        formattedNumber: numericNumber,
        numericNumber,
      };
    }
  } else if (number.startsWith("9")) {
    var numericNumber = number.replace(/\D/g, "").replace("-","");;
   
    // Check if the number starts with "05" and has 9 digits
    if (/^972\d{9}$/.test(numericNumber)) {
      // Format the number as "+972-500000023"
      var formattedNumber = numericNumber.replace(/(\d{3})(\d{9})/, "$1-$2");

      return {
        isValid: true,
        formattedNumber: formattedNumber,
        numericNumber,
      };
    } else {
      return {
        isValid: false,
        formattedNumber: numericNumber,
        numericNumber,
      };
    }
  } else if (number.startsWith("0")) {
    var numericNumber = number.replace(/\D/g, "").replace("-","");;


    // Check if the number starts with "05" and has 9 digits
    if (/^05\d{8}$/.test(numericNumber)) {
      // Format the number as "050-0000023"
      var formattedNumber = numericNumber.replace(/(\d{3})(\d{7})/, "$1-$2");

      return {
        isValid: true,
        formattedNumber: formattedNumber,
        numericNumber,
      };
    } else {
      return {
        isValid: false,
        formattedNumber: numericNumber,
        numericNumber,
      };
    }
  } else {
    const phoneNumber = parsePhoneNumber(number, "IL");
    return {
      isValid: phoneNumber?.isValid(),
      formattedNumber: number,
      number,
    };
  }
}
export function prepareErrorMessage(error) {
  if (error?.errors?.length) {
    const e = error?.errors?.[0];
    if (e?.message) {
      return `${error.message}: ${e.message}`;
    }
  }
  return `${error.message}`;
}

export function showServerError(error) {
  const errorMessage = safeParseJSON(error?.message);
  toastService.error(
    prepareErrorMessage(errorMessage) || translate("COMMON.ERRORS.MESSAGE")
  );
}

export function formatUUID(uuid) {
  return uuid?.split("-")?.[0] || "-";
}

export function formatTableDateTime(dt) {
  if (!dt) {
    return "-";
  }
  return dayjs(dt).format("DD/MM/YYYY, HH:mm");
}

export function formatTableTime(dt) {
  if (!dt) {
    return "-";
  }
  return dayjs(dt).format("HH:mm");
}

export function formatTableDate(dt) {
  if (!dt) {
    return "-";
  }
  return dayjs(dt).format("DD/MM/YYYY");
}



export function formatHumanizedDuration(startDate?: string | null, endDate?: string | null) {
  // Parse the startDate and endDate
  if (!startDate) return '-';
  const start = DateTime.fromISO(startDate);
  const end = endDate ? DateTime.fromISO(endDate) : DateTime.now();

  // Calculate the duration between the two dates
  const diff = end.diff(start);

  // Extract days, hours, minutes, and seconds from the duration
  const days = Math.floor(diff.as('days'));
  const hours = Math.floor(diff.as('hours') % 24);
  const minutes = Math.floor(diff.as('minutes') % 60);
  const seconds = Math.floor(diff.as('seconds') % 60);

  // Construct the human-readable format
  let result = '';
  if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
  if (hours > 0 || days > 0) result += `${hours}h `;
  if (minutes > 0 || hours > 0 || days > 0) result += `${minutes}min `;
  if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) result += `${seconds}s`;

  return result.trim();
}




export function adjustCustomQuestionSingleLength(newArray: any) {
  const desiredLength = SingleMultiData.length;

  if (newArray.length < desiredLength) {
    // Add default items until the array length equals the desired length
    while (newArray.length < desiredLength) {
      newArray.push({singleSelect: SingleMultiData[0]});
    }
  }
  
  return newArray;
}

export const removeMarkupTags = (text: string) => {
  const removed = getQuestionBodyText(text).toLocaleLowerCase(); 
  return removed; 
};

export const getQuestionBodyText = (value: string) => {
  const val = value
    .replace(/<u>|<\/u>/g, "") // Remove <u> and </u> tags
    .replace(/[#*`~>\[\]]+/g, "") // Remove Markdown characters
    .replace(/!\[.*\]\(.*\)/g, "") // Remove images
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Keep link text, remove URLs
    .replace(/\n+/g, "\n") // Normalize new lines
    .replace(/&x20;/g, '')
  return val.length > 55 ? val.substring(0, 55) + "..." : val;
};
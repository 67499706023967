import { Box } from '@mui/material'
import React from 'react'
import logo from 'assets/logo.png'
import ContactItem, { Type } from './components/ContactItem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { MIFRA_DETAILS } from 'utils/constants'

const ContactInfo = MIFRA_DETAILS

export default function Footer() {
    return (
        <Grid2
            container
            spacing={3}
            sx={{
                padding: "16px",
                background: "#26A8E0",
                alignItems: "center",
            }}
        >
            <Grid2 md={4} sm={12} xs={12}>
                <img alt="logo" src={logo} width="145px" height="50px" />
            </Grid2>
            {
                ContactInfo?.map((item, i) => {
                    return (
                        <Grid2 md={2}>
                            <ContactItem 
                                type={item?.type}
                                text={item?.value}
                                link={item?.link}
                            />
                        </Grid2>
                    )
                }) 
            }
        </Grid2>
    )
}

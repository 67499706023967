import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL, translate } from 'utils/constants';
import { Res, request } from 'utils/request';
import { actions } from './slice';
import { QuestionsResponse, Station, StationTypes, StationsResponse } from '../types';
import { removeBlankProperties } from 'utils/helpers';
import toastService from 'utils/toast';
import { Query } from 'app/containers/types';
import { selectPagination } from './selector';
import { showServerError } from 'styles/theme/utils';
import { actions as authAction } from 'app/redux/slice';
import { selectOrganization } from 'app/redux/selectors';


export function* getStationRequest({
    payload,
  }: {
    payload: { id: string; duplicate?: boolean };
  }) { 
    yield delay(500);
    try {
        const { id, duplicate } = payload;
        const {data, status, err}: Res<{ data: Station}> = yield call(request, `${API_URL}/v1/admin/station/get/${id}`);
        
        if(err || !data){
            throw err;
        }
        yield put(actions.getStationSuccess({
            ...data?.data,
            name: duplicate ? `Copy of ${data?.data?.name}` : data?.data?.name
        }));
    } catch (error:any) {
        yield put(actions.getStationError());
        showServerError(error)
    }
}

export function* getStationsRequest({payload}: { payload: Query }) { 
    yield delay(500);
    try {
        const query = new URLSearchParams(removeBlankProperties(payload)).toString();
        const {data, status, err}: Res<StationsResponse> = yield call(request, `${API_URL}/v1/admin/station/list?${query}`);
        
        if(err || !data){
            throw err;
        }
        yield put(actions.getStationsSuccess({
            stations: data?.data,
            pagination: data?._metadata.pagination
        }));
    } catch (error:any) {
        yield put(actions.getStationsError());
        showServerError(error)
    }
}

export function* createStationRequest({payload}: { payload: {station: Station; callback?: ()=>void;}}) {
    yield delay(500);
    try {
        const location = {
            type: payload.station.location?.type,
        }
        if(payload.station.location?.type === StationTypes.POINT) {
            location['address'] = payload.station.location?.address;
            location['radius'] = payload.station.location?.radius;
            location['isAddressVisible'] = payload.station.location?.isAddressVisible;
            location['coordinates'] = payload.station.location?.coordinates;
        }
        if(payload.station.location?.type === StationTypes.QR) {
            location['code'] = payload.station.location?.code ?? '';
        }
        if(payload.station.location?.type === StationTypes.CODE) {
            location['code'] = payload.station.location?.code;
        }
        const {err, status} = yield call(request, `${API_URL}/v1/admin/station/create`, {
            method: 'POST',
            body: JSON.stringify({
                location,
                stationImageId: payload.station.stationImage._id,
                stationInstruction: payload.station.stationInstruction,
                feedBackOnSuccessfulArrival: payload.station.feedBackOnSuccessfulArrival,
                goToMessage1: payload.station.goToMessage1,
                goToMessage2: payload.station.goToMessage2,
                name: payload.station.name,
                organizationId: payload.station.organization,
                stationQuestions: payload.station.stationQuestions,
            })
        });
        
        if(err){
            throw err;
        }
        yield put(actions.createStationSuccess());
        toastService.success(translate("STATIONS.SUCCESSFULLY_CREATED"));
        payload?.callback?.();
    } catch (error:any) {
        yield put(actions.createStationError());
        showServerError(error)
    }
}
export function* updateStationRequest({payload}: { payload: {station: Station; callback?: ()=>void;}}) {
    yield delay(500);
    try {
        const location = {
            type: payload.station.location?.type,
        }
        if(payload.station.location?.type === StationTypes.POINT) {
            location['address'] = payload.station.location?.address;
            location['radius'] = payload.station.location?.radius;
            location['coordinates'] = payload.station.location?.coordinates;
            location['isAddressVisible'] = payload.station.location?.isAddressVisible;
        }
        if(payload.station.location?.type === StationTypes.QR) {
            location['code'] = payload.station.location?.code ?? '';
        }
        if(payload.station.location?.type === StationTypes.CODE) {
            location['code'] = payload.station.location?.code;
        }
        const {data, status, err}: Res<{ data: Station }> = yield call(request, `${API_URL}/v1/admin/station/update/${payload.station?._id}`, {
            method: 'PUT',
            body: JSON.stringify({
                location,
                stationImageId: payload.station.stationImage._id,
                stationInstruction: payload.station.stationInstruction,
                feedBackOnSuccessfulArrival: payload.station.feedBackOnSuccessfulArrival,
                goToMessage1: payload.station.goToMessage1,
                goToMessage2: payload.station.goToMessage2,
                name: payload.station.name,
                organizationId: payload.station.organization,
                stationQuestions: payload.station.stationQuestions,
            })
        });
        
        if(err || !data){
            throw err;
        }
        yield put(actions.updateStationSuccess(data?.data));
        toastService.success(translate("STATIONS.SUCCESSFULLY_UPDATED"));
        payload?.callback?.();
    } catch (error:any) {
        yield put(actions.updateStationError());
        showServerError(error)
    }
}
export function* deleteStationRequest({payload}: { payload: string }) {
    yield delay(500);
    try {
        const organization = yield select(selectOrganization);
        const {err, status} = yield call(request, `${API_URL}/v1/admin/station/delete/${payload}`, {
            method: 'DELETE',
        });
        
        if(err){
            throw err;
        }
        yield put(actions.deleteStationSuccess());
        const pagination = yield select(selectPagination);
        toastService.success(translate("STATIONS.SUCCESSFULLY_DELETED"));
        yield put(actions.getStations({
            perPage: pagination.perPage,
            page: pagination.page,
            search: pagination.search,
            isActive: pagination.filters?.isActive,
            orderBy: pagination.orderBy,
            organization: organization ? organization.value : undefined,
            orderDirection: pagination.orderDirection,
        }));
    } catch (error:any) {
        yield put(actions.deleteStationError());
        showServerError(error)
    }
}

export function* getQuestionsRequest({payload}:{payload: Query}){ 
    yield delay(500);
    try {
        const query = new URLSearchParams(removeBlankProperties(payload)).toString();
        const {data, err, status}: Res<QuestionsResponse> = yield call(request, `${API_URL}/v1/admin/question/list?${query}`);
        
        if(err || !data){
            throw err;
        }
        yield put(actions.getQuestionsSuccess({
            questions: data?.data,
            pagination: data?._metadata.pagination
        }));
    } catch (error:any) {
        yield put(actions.getQuestionsError());        
        showServerError(error)
    }
}

export function* generateBarcodeRequest({payload}:{payload:string}) {
    yield delay(500);
    try {
        const query = new URLSearchParams(removeBlankProperties({text: payload})).toString();
        const {data, err, status} = yield call(request, `${API_URL}/v1/admin/address/qr?${query}`);
        
        if(err || !data){
            throw err;
        }
        yield put(actions.setBarcode(data?.data?.qr));
    } catch (error:any) {
        showServerError(error)
    }
}
export function* resetFrom({
    payload,
  }: {
    payload: { callback?: () => void };
  }) {
    yield delay(500);
    try {
      payload?.callback?.();
    } catch (error: any) {}
  }
export function* stationsSaga() {
    yield takeLatest(actions.getStations, getStationsRequest);
    yield takeLatest(actions.getStation, getStationRequest);
    yield takeLatest(actions.createStation, createStationRequest);
    yield takeLatest(actions.updateStation, updateStationRequest);
    yield takeLatest(actions.deleteStation, deleteStationRequest);
    yield takeLatest(actions.getQuestions, getQuestionsRequest);
    yield takeLatest(actions.generateBarcode, generateBarcodeRequest);
    yield takeLatest(actions.resetForm, resetFrom);
}
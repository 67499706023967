import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./components/Header";
import Table from "./components/Table";
import GroupPhotos from "./components/GroupPhotos";
import Footer from "./components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { actions } from "../../redux/slice";
import { useDispatch } from "react-redux";
import { resultsReducer, resultsSlice } from '../../redux/slice';
import { resultsSaga } from '../../redux/saga';
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import CreativeAssignments from "./components/CreativeAssignments";
import StationModal from "./components/StationModal";
import GameDetails from "./components/Header/components/GameDetails";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../redux/selector";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import i18next from "i18next";
import { themeActions } from "styles/theme/slice";
import { SupportedLanguage, SUPPORTED_LANGUAGES } from "utils/constants";

export default function ResultsDetails() {
  useInjectReducer({ key: resultsSlice, reducer: resultsReducer });
  useInjectSaga({ key: resultsSlice, saga: resultsSaga });
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLoading = useSelector(selectIsLoading)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {height,width}= useWindowDimensions()

  const [searchParams] = useSearchParams();
  const hl = searchParams.get("hl") || "en";

  const changeLanguage = (l: SupportedLanguage) => {
    i18next.changeLanguage(l);
    dispatch(themeActions.toggleDirection(l === "he" ? "rtl" : "ltr"));
  };
  useEffect(() => {
    if (SUPPORTED_LANGUAGES.includes(hl)) {
      if (i18next.language !== hl) {
        changeLanguage(hl as SupportedLanguage);
      }
    }
  }, [hl, SUPPORTED_LANGUAGES])
  useEffect(() => {
    if (!id) return;
      dispatch(actions.getTicketResult(id));
  }, [id]);

  const handleCurrentResult = (id?: string) => {
    dispatch(actions.setCurrentResult(id))
  }
  
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: "#F3F6F8",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          overflowY: "auto",
          overflowX: 'hidden',
        }}
      >
        <Header isMobile={isMobile} />
        {isLoading ? <>
          <Box width={width} height={height/2} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" size={50} />
          </Box>
        </> : <>
        <GameDetails isMobile={isMobile} />
        <Table isMobile={isMobile}/>
        <GroupPhotos isMobile={isMobile}/>
        <CreativeAssignments isMobile={isMobile} />
        <Footer />
        <StationModal  onClose={() => handleCurrentResult(undefined)} />
        </>}
    
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import StationTypeForm from "./components/StationTypeForm";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import StationImage from "./components/StationImage";
import Questions from "./components/Questions";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { actions } from "../../redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { actions as actionsOrganization } from "app/redux/slice";
import {
  selectIsLoading,
  selectIsUnsaved,
  selectSelectedQuestions,
  selectStation,
} from "../../redux/selector";
import { selectOrganization } from "app/redux/selectors";
import { validateStation } from "./validator";
import toastService from "utils/toast";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "app/components/ConfirmDialog";
import { StationTypes } from "../../types";
import Option from "app/components/ConfirmDialog/Component/Option";
import { Box } from "@mui/material";
import useOrgValidator from "app/containers/Auth/containers/component/OrgValidator";
import NavigationConfirmDialog from "app/components/NavigationConfirmDialog";
import InstructionModal from "app/components/InstructionModal";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const [duplicatePopup, setDuplicatePopup] = useState(false)
  const dispatch = useDispatch();
  const isUnsaved = useSelector(selectIsUnsaved);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(0);
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const station = useSelector(selectStation);
  useOrgValidator(station.organizationId.value);
  const organization = useSelector(selectOrganization);
  const questions = useSelector(selectSelectedQuestions);
  const { t } = useTranslation();

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(
      actions.getStation({ id: id, duplicate: mode === MODES.DUPLICATE })
    );
  }, [mode, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);

  const styles = useStyles();
  const navigate = useNavigate();

  const handleCancelBtn = () => {
    if (isUnsaved) {
      setShowConfirm(true);
      return;
    }

    dispatch(
      actions.resetForm({
        callback: () => {
          navigate("/app/stations");
        },
      })
    );
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit(false);
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
  };
  const onSubmit = (isDuplicate: boolean) => {
    const errors = validateStation(station, t);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    // if (questions?.length < 1) {
    //   toastService.error(
    //     t("STATIONS.FORM.PLEASE_SELECT_AT_LEAST_ONE_QUESTION")
    //   );
    //   return;
    // }
    dispatch(
      actions.createStation({
        station: {
          organization: organization?.value ?? "",
          name: `${isDuplicate ? "Copy Of " : ""} ${station?.name.value}`,
          goToMessage1: station?.goToMessage1?.value,
          goToMessage2: station?.goToMessage2?.value,
          feedBackOnSuccessfulArrival:
            station?.feedBackOnSuccessfulArrival.value,
          stationInstruction: station?.stationInstruction?.value,
          location: {
            coordinates: station?.coordinates?.value,
            type: station?.type?.value,
            code:
              station?.type?.value === StationTypes.CODE
                ? station?.code?.value
                : station?.qrText?.value,
            radius: station?.radius?.value,
            address: station?.address?.value,
            isAddressVisible: station?.isAddressVisible?.value,
          },
          stationQuestions: questions,
          stationImage: {
            _id: station?.stationImageId?.value,
            url: station?.stationImageUrl?.value,
          },
        },
        callback: () => {
          if(mode === MODES.DUPLICATE) {
            setDuplicatePopup(true) 
          } else{
            dispatch(
              actions.resetForm({
                callback: () => {
                  navigate("/app/stations");
                },
              })
            );
          }
     
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateStation(station, t);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    // if (questions?.length < 1) {
    //   toastService.error(
    //     t("STATIONS.FORM.PLEASE_SELECT_AT_LEAST_ONE_QUESTION")
    //   );
    //   return;
    // }
    dispatch(
      actions.updateStation({
        station: {
          organization: organization?.value ?? "",
          name: station?.name.value,
          goToMessage1: station?.goToMessage1?.value,
          goToMessage2: station?.goToMessage2?.value,
          feedBackOnSuccessfulArrival:
            station?.feedBackOnSuccessfulArrival.value,
          stationInstruction: station?.stationInstruction?.value,
          location: {
            coordinates: station?.coordinates?.value,
            type: station?.type?.value,
            code:
              station?.type?.value === StationTypes.CODE
                ? station?.code?.value
                : station?.qrText?.value,
            radius: station?.radius?.value,
            address: station?.address?.value,
            isAddressVisible: station?.isAddressVisible?.value,
          },
          stationQuestions: questions,
          stationImage: {
            _id: station?.stationImageId?.value,
            url: station?.stationImageUrl?.value,
          },
          _id: station?.id.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/stations");
              },
            })
          );
        },
      })
    );
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit(false);
      return;
    }
    if (mode === MODES.EDIT) {
      if (isUnsaved) {
        setShowSaveConfirm(true);
      } else {
        onUpdate();
      }
      return;
    }
    navigate(`/app/stations/${id}/edit`);
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      dispatch(
        actions.setFormValues({
          key: "name",
          value: "Copy Of " + station.name.value,
        })
      );
      setTimeout(() => {
        onSubmit(true);
      }, 500);
    }
    setShowSaveConfirm(false);
    setSaveOption(0);
  };
  useEffect(() => {
    dispatch(actionsOrganization.getSetEnableOrganization(true));

    return () => {
      dispatch(actionsOrganization.getSetEnableOrganization(false));
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);

  return (
    <>
      <div className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          title={generatePageTitle(Modules.STATIONS, mode)}
          onBackClick={handleCancelBtn}
        />
        <Box>
          <ActionButtons
            handleCancelBtn={handleCancelBtn}
            handleConfirmBtn={handleConfirmBtn}
            mode={mode}
            isLoading={isLoading}
          />
        </Box>
      </div>
      <InstructionModal instructions={t('COMMON.CONFIRM_OPTIONS.STATIONS.SAVE_ANOTHER_COPY_HELP')} open={duplicatePopup} onClose={()=> {
        setDuplicatePopup(false)
        dispatch(
          actions.resetForm({
            callback: () => {
              navigate("/app/stations");
            },
          })
        );
        }}/>
      {/**Question Details Form */}
      <Form mode={mode} />
      <div style={{ height: "30px" }} />
      <Grid2 container spacing={2}>
        <StationTypeForm mode={mode} />
        <StationImage mode={mode} />
      </Grid2>
      <div style={{ height: "30px" }} />
      <Questions mode={mode} />
      <div style={{ height: "30px" }} />
      <NavigationConfirmDialog
        title={t("COMMON.SCREEN_NAME.GAME")}
        isUnsaved={isUnsaved}
        onConfirm={handelConfirm}
      ></NavigationConfirmDialog>
      <ConfirmDialog
        open={showConfirm}
        handleClose={(val) => {
          if(val) {
            setShowConfirm(false);
            dispatch(
              actions.resetForm({
                callback: () => {
                    navigate("/app/stations");
                },
              })
            );
          }else{
            setShowConfirm(false);
          }
          
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.STATION"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={(val) => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            setSaveOption(e);
          }}
          saveInstanceHelp={t("COMMON.CONFIRM_OPTIONS.STATIONS.SAVE_INSTANCE_HELP")}
          saveCopyHelp={t("COMMON.CONFIRM_OPTIONS.STATIONS.SAVE_ANOTHER_COPY_HELP")}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;

import { FormElement, Metadata, OrderDirections, Pagination } from "app/containers/types";
import { RoleTypes } from "app/types";

export interface EditorsListResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Editor[];
}

export type EditorForm = {
  _id: FormElement;
  email: FormElement;
  fullName: FormElement;
  mobileNumber: FormElement;
  organizationId: FormElement;
  departmentName: FormElement;
  noOfQuestions: FormElement;
  noOfGames: FormElement;
  lastLogin: FormElement;
  role?: RoleTypes,
};

export type EditorsState = {
  form: EditorForm;
  editors: Array<Editor>;
  pagination: Pagination;
  loading: boolean;
  isUnsaved: boolean;
};

export const EditorsState: EditorsState = {
  editors: [],
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 40,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  loading: false,
  form: {
    _id: { value: "" },
    email: { value: "" },
    fullName: { value: "" },
    mobileNumber: { value: "" },
    organizationId: { value: "" },
    departmentName: { value: "" },
    noOfQuestions: { value: "0" },
    noOfGames: { value: "0" },
    lastLogin: { value: "" },
    role: undefined
  },
  isUnsaved:false
};

export interface Editor {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  fullName: string;
  mobileNumber: string;
  noOfQuestions?: number;
  noOfGames?: number;
  email: string;
  role?: null;
  passwordExpired?: string;
  passwordCreated?: string;
  passwordAttempt?: number;
  signUpDate?: string;
  signUpFrom?: string;
  isActive?: boolean;
  inactivePermanent?: boolean;
  blocked?: boolean;
  organizationId?: string;
  organization?: string;
  departmentName: string;
  lastLogin?: string;
  lastLoginDate?:string;
}

export type ContainerState = EditorsState;

import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import AddPhotoAlternateTwoToneIcon from "@mui/icons-material/AddPhotoAlternateTwoTone";
import MediaUploader from "app/components/MediaUploader";
import { useTranslation } from "react-i18next";
import { CreateAssetResponse } from "app/components/DragNDropDialog/types";

export default function Uploader({
  isOdd = false,
  onUpload,
  isDelete = false,
  onDelete,
  src,
  disabled = false,
}: {
  isOdd?: boolean;
  isDelete?: boolean;
  onUpload: (url?: CreateAssetResponse) => void;
  onDelete: () => void;
  src?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "6px",
      }}
    >
      <MediaUploader
        caption={t("QUESTIONS.FORM.LABELS.IMAGE_CAPTION")}
        height="200px"
        width="320px"
        disabled={disabled}
        showBtn={false}
        onSuccess={onUpload}
        src={src}
        title={t("COMMON.LABELS.UPLOAD_IMAGE")}
      >
        <Box
          sx={{
            minWidth: "124px",
            height: "24px",
            borderRadius: "4px",
            backgroundColor: isOdd ? "#F3F6F8" : "#FFFFFF",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "6px",
          }}
        >
          {src ? (
            <img
              src={src}
              style={{ width: "20px", height: "20px", marginLeft: "-6px" }}
            />
          ) : (
            <AddPhotoAlternateTwoToneIcon
              sx={{
                fontSize: "12px",
                color: "#8890A6",
              }}
            />
          )}

          <Typography
            sx={{
              color: "#5E6781",

              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "12px",
              letterSpacing: "0em",
            }}
          >
            {t("COMMON.LABELS.UPLOAD_IMAGE")}
          </Typography>
        </Box>
      </MediaUploader>
      {!disabled && isDelete && (
        <IconButton
          sx={{
            backgroundColor: isOdd ? "#F3F6F8" : "#FFFFFF",
            width: "40px",
            height: "24px",
            borderRadius: "4px",
          }}
          disabled={disabled}
          onClick={onDelete}
        >
          <DeleteForeverTwoToneIcon
            sx={{
              fontSize: "16px",
              color: "#E24399",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import React from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

export type Type = "phone" | "address" | "email" | "website";

const renderIcon = (type: Type) => {
  switch (type) {
    case "phone":
      return (
        <LocalPhoneOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: "#E24399",
            color: "#FFFFFF",
            borderRadius: "4px",
            padding: "2px",
          }}
        />
      );
    case "address":
      return (
        <RoomOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: "#E24399",
            color: "#FFFFFF",
            borderRadius: "4px",
            padding: "2px",
          }}
        />
      );
    case "email":
      return (
        <EmailOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: "#E24399",
            color: "#FFFFFF",
            borderRadius: "4px",
            padding: "2px",
          }}
        />
      );
    case "website":
      return (
        <LanguageOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: "#E24399",
            color: "#FFFFFF",
            borderRadius: "4px",
            padding: "2px",
          }}
        />
      );
    default:
      break;
  }
};

export default function ContactItem({
  type,
  text,
  link,
}: {
  type: string;
  text: string;
  link?: string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
      }}
    >
      {renderIcon(type as Type)}
      <a href={link} style={{ textDecoration: "none" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0em",
            color: "#FFFFFF",
          }}
        >
          {text}
        </Typography>
      </a>
    </Box>
  );
}

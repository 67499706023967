import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

interface ThumbnailProps {
  url: string;
  thumbUrl?: string;
  name?: string;
  onClick: () => void;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  thumbUrl,
  url,
  name,
  onClick,
}) => {
  const isVideo = url.match(/\.(mp4|webm|ogg|mov)$/i);
  const isText = !isVideo && !url.match(/\.(jpg|jpeg|png|gif)$/i);

  const extractImageName = (imageUrl) => {
    const url = new URL(imageUrl);
    const path = url.pathname;
    const fileName = path.split('/').pop(); // Extracts the filename from the path
    return fileName || 'downloaded.jpg';
  };

  const downloadImage = (e) => {
    e.stopPropagation()
    const a = document.createElement('a');
    a.href = url;
    a.download = extractImageName(url); // You can set the desired filename here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        width: "250px",
        height: "150px",
        cursor: "pointer",
        borderRadius: "12px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
      {isVideo ? (
        <>
          {thumbUrl ? (
            <img
              src={thumbUrl}
              alt={name}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          ) : (
            <video
              src={url}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              muted
              playsInline
              loop
            />
          )}
          <PlayCircleOutlineIcon
            sx={{
              position: "absolute",
              color: "#207DB9",
              fontSize: "48px",
            }}
          />
        </>
      ) : isText ? (
        <Typography
          variant="body2"
          sx={{
            padding: "8px",
            textAlign: "center",
            color: "#333",
          }}
        >
          {name?.substring(0, 50)}...
        </Typography>
      ) : (
        <img
          src={thumbUrl || url}
          alt={name}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      )}
      {!isText && (
        <IconButton
          sx={{ position: "absolute", bottom: "5px", right: "5px",  background: 'rgb(32 125 185 / 71%)', '&:hover': {
          color: 'rgb(32 125 185 / 71%)', // Hover color
        },}}
          onClick={downloadImage}
        >
          <FileDownloadOutlinedIcon
            sx={{
              color: "#fff",
              fontSize: "16px",
             
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default Thumbnail;

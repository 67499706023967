import React from "react";

const TermsOfUse = () => {
  return (
    <div className="page">
      <div
        id="privacy"
        style={{ padding: "12px", margin: "auto", maxWidth: "1200px" }}
      >
        <header>
        <h1>Privacy Policy</h1>
          <p>
            Effective Date: 2 March 2023
            <br />
            Last Updated: 8 January 2024
          </p>
        </header>

        <p>
          TwiGo values your privacy and is committed to protecting your personal
          information. This Privacy Policy explains how we collect, use, and
          safeguard your data.
        </p>

        <h2>1. Information We Collect</h2>
        <p>TwiGo may collect the following types of information:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, and geolocation data (when permitted by you).
          </li>
          <li>
            <strong>Usage Information:</strong> Details about your interactions
            with the app, including accessed features, time spent, and app
            performance metrics.
          </li>
          <li>
            <strong>Device Information:</strong> Technical data such as device
            model, operating system, and unique identifiers.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>Your information is used to:</p>
        <ul>
          <li>Personalize your experience within the app.</li>
          <li>Improve app functionality and user experience.</li>
          <li>
            Send notifications about updates, events, or promotional content
            (with your consent).
          </li>
          <li>Ensure compliance with legal and security requirements.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. However, your information may be shared in the following
          scenarios:
        </p>
        <ul>
          <li>
            <strong>With Event Organizers:</strong> If you participate in
            third-party-hosted events via TwiGo, relevant data may be shared
            with event organizers.
          </li>
          <li>
            <strong>Compliance:</strong> When required by law, regulation, or
            legal processes.
          </li>
          <li>
            <strong>Business Transfers:</strong> If TwiGo is involved in a
            merger, acquisition, or sale, your information may be transferred to
            the new entity.
          </li>
        </ul>

        <h2>4. Security Measures</h2>
        <p>
          We use encryption, secure storage, and regular monitoring to protect
          your data. While we strive to maintain the highest security standards,
          no system is 100% secure.
        </p>

        <h2>5. Your Privacy Rights</h2>
        <p>Depending on your location, you may have the right to:</p>
        <ul>
          <li>Request access to your personal data.</li>
          <li>Correct inaccuracies or delete your information.</li>
          <li>Restrict or object to data processing.</li>
        </ul>
        <p>
          To exercise these rights, contact us at{" "}
          <a href="mailto:nurit@twigoapp.com">nurit@twigoapp.com</a>.
        </p>

        <h2>6. Children’s Privacy</h2>
        <p>
          TwiGo is not intended for children under 13. If we become aware of
          unauthorized data collection from minors, we will promptly delete it.
        </p>

        <h2>7. Data Retention</h2>
        <p>
          We retain your information only as long as necessary to provide our
          services and comply with legal obligations.
        </p>

        <h2>8. Third-Party Services</h2>
        <p>
          TwiGo may link to third-party services or integrate features from
          external platforms. We are not responsible for the privacy practices
          of these services. Please review their policies before sharing data.
        </p>

        <h2>9. Updates to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically. Changes will be
          effective upon posting on our website.
        </p>

        <h2>Contact Us</h2>
        <p>For privacy inquiries or concerns, please contact:</p>
        <ul>
          <li>
            <strong>Name:</strong> Nurit Shehter
          </li>
          <li>
            <strong>Email:</strong>{" "}
            <a href="mailto:nurit@twigoapp.com">nurit@twigoapp.com</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsOfUse;

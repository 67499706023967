

import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function MobileStack({
    label,
    value
}:{
    label: string,
    value: string
}) {
    const { t } = useTranslation()
    return (
        <Grid container spacing={2} sx={{marginTop: '5px', marginBottom: '5px'}}>
          <Grid item xs={6} >
            <Typography variant="body2" sx={{  fontSize: "14px",
                    fontWeight: 500, textAlign: "left" }}>
            {t(`RESULTS.LABELS.${label}`)}
            </Typography>
          </Grid>
          <Grid item xs={6} >
            <Typography variant="body2" sx={{  fontSize: "14px",
                    fontWeight: 500, textAlign: "right" }}>
             {value}
            </Typography>
          </Grid>

        </Grid>
    )
}

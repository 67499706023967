import PopupDialog from "app/components/PopupDialog";
import React, { useCallback, useEffect, useState } from "react";
import UploadView from "./components/UploadView";
import Layout from "./components/Layout";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { mediaUploadReducer, mediaUploadSlice } from "./redux/slice";
import { mediaUploadSaga } from "./redux/saga";
import { CreateAssetResponse } from "./types";
import { useDropzone } from "react-dropzone";
import { MediaTypes } from "app/containers/types";
import toastService from "utils/toast";
import { useTranslation } from "react-i18next";

const imageMeta = {
  "image/png": ["image/png", "image/jpeg", "image/jpg"],
};
const videoMeta = {
  "video/mp4": ["video/mp4", "video/x-m4v", "video/*"],
};
const pdfMeta = {
  "application/pdf": ["application/pdf"],
};

const getFileMeta = (type: MediaTypes) => {
  switch (type) {
    case "image":
      return imageMeta;
    case "video":
      return videoMeta;
    case "pdf":
      return pdfMeta;
    case MediaTypes.IMAGE_VIDEO:
      return { ...imageMeta, ...videoMeta };
    default:
      return imageMeta;
  }
};

const videoTypes = [
  "video/mp4",
  "video/x-m4v",
  "video/*",
  "video/quicktime",
  "video/x-ms-wmv",
  "video/x-msvideo",
  "video/3gpp",
];

interface Props {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly mediaType: MediaTypes;
  readonly onSuccess?: (data?: CreateAssetResponse) => void;
  readonly src?: string;
  readonly title?: string;
  readonly width?: string;
  readonly height?: string;
  readonly caption?: string;
  readonly fallbackWidth: string;
  readonly fallbackHeight: string;
  setErrors: any;
}

export default function DragNDropDialog({
  open,
  handleClose,
  mediaType,
  onSuccess,
  src,
  title,
  width,
  height = "200px",
  caption,
  fallbackWidth,
  fallbackHeight,
  setErrors,
}: Props) {
  useInjectReducer({ key: mediaUploadSlice, reducer: mediaUploadReducer });
  useInjectSaga({ key: mediaUploadSlice, saga: mediaUploadSaga });
  const [localSrc, setLocalSrc] = React.useState<string | undefined>(undefined);
  const [files, setFiles] = React.useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      setError("");
      setErrors("");
      if (fileRejections?.length > 0) {
        return toastService.error(t("COMMON.ERRORS.INVALID_FILE_FORMAT"));
      }
      if (fallbackWidth && fallbackHeight && mediaType === MediaTypes.IMAGE) {
        const img = new Image();
        img.src = URL.createObjectURL(acceptedFiles?.[0]);
        img.onload = () => {
          if (
            img.width !== parseInt(fallbackWidth?.replace("px", "")) ||
            img.height !== parseInt(fallbackHeight?.replace("px", ""))
          ) {
            setError(t("COMMON.ERRORS.INVALID_IMAGE_DIMENSIONS"));
            setErrors(t("COMMON.ERRORS.INVALID_IMAGE_DIMENSIONS"));
          }
        };

        if (acceptedFiles?.[0].size > 3000000) {
          return toastService.error(
            t("COMMON.ERRORS.INVALID_IMAGE_SIZE_MAX_3MB")
          );
        }

        setFiles(acceptedFiles);
      }
      if (
        fallbackWidth &&
        fallbackHeight &&
        mediaType === MediaTypes.IMAGE_VIDEO
      ) {
        const img = new Image();
        img.src = URL.createObjectURL(acceptedFiles?.[0]);
        img.onload = () => {
          if (
            img.width !== parseInt(fallbackWidth?.replace("px", "")) ||
            img.height !== parseInt(fallbackHeight?.replace("px", ""))
          ) {
            setError(t("COMMON.ERRORS.INVALID_IMAGE_DIMENSIONS"));
            setErrors(t("COMMON.ERRORS.INVALID_IMAGE_DIMENSIONS"));
          }
        };
        if (acceptedFiles?.[0].type == "video/mp4") {
          if (acceptedFiles?.[0].size > 100000000) {
            return toastService.error(
              t("COMMON.ERRORS.INVALID_VIDEO_SIZE_MAX_100MB")
            );
          }
          setFiles(acceptedFiles);
        } else if (
          acceptedFiles?.[0].type == "image/png" ||
          acceptedFiles?.[0].type == "image/jpeg" ||
          acceptedFiles?.[0].type == "image/jpg"
        ) {
          if (acceptedFiles?.[0].size > 3000000) {
            return toastService.error(
              t("COMMON.ERRORS.INVALID_IMAGE_SIZE_MAX_3MB")
            );
          }
          setFiles(acceptedFiles);
        }
      }
      if (mediaType === "pdf") {
        if (acceptedFiles?.[0].type !== "application/pdf") {
          return toastService.error(t("COMMON.ERRORS.INVALID_FILE_FORMAT"));
        }
        setFiles(acceptedFiles);
      }
      if (mediaType === "video") {
        if (!videoTypes.includes(acceptedFiles?.[0].type)) {
          return toastService.error(t("COMMON.ERRORS.INVALID_FILE_FORMAT"));
        }
        if (acceptedFiles?.[0].size > 100000000) {
          return toastService.error(
            t("COMMON.ERRORS.INVALID_VIDEO_SIZE_MAX_100MB")
          );
        }
        setFiles(acceptedFiles);
      }
    },
    [fallbackWidth, height, mediaType]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: getFileMeta(mediaType),
  });
  useEffect(()=>{
    setLocalSrc(src)
  }, [src])

  const onCancel = () => {
    setFiles([]);
    handleClose();
  };
  const onRemove = () => {
    setFiles([]);
    setLocalSrc(undefined)
    setError('')
    setErrors('')
  };
  const showSaveAndClose = localSrc === undefined && !!src

  return (
    <PopupDialog
      containerStyle={{
        width: "700px",
        margin: "5px",
      }}
      open={open}
      handleClose={files?.length > 0 ? undefined : handleClose}
    >
      <Layout
        acceptedFiles={files}
        showSaveAndClose={showSaveAndClose}
        onCancel={onCancel}
        type={mediaType}
        onUpload={(img) => {
          onSuccess?.(img);
        }}
        title={title}
      >
        <UploadView
          onRemove={onRemove}
          type={mediaType}
          acceptedFiles={files}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          src={localSrc}
          width={width}
          height={height}
          caption={caption}
          error={error}
        />
      </Layout>
    </PopupDialog>
  );
}

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { QuestionsState } from '../types';

const selectDomain = (state: RootState) => state.questionsState || QuestionsState;

export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectList = createSelector([selectDomain], state => state.list);

export const selectPagination = createSelector([selectDomain], state => state.pagination);

export const selectError = createSelector([selectDomain], state => state.error);
export const selectForm= createSelector([selectDomain], state => state.form);
export const selectAnswer= createSelector([selectDomain], state => state.localAnswer);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);
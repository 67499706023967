import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./component/Header";
import Image from "./component/Image";
import Summary from "./component/Summary";
import MidSection from "./component/MidSection";
import CustomizedQuestions from "./component/CustomizedQuestions";
import Footer from "./component/CustomizedQuestions/components/Footer";
import ConfirmDelete from "./component/ConfirmDelete";
import QuestionForm from "./component/QuestionForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { actions } from "../../redux/slice";
import ActionButtons from "./component/QuestionForm/components/ActionButtons";
import {
  selectCustomizeTicket,
  selectCustomizeTicketQuestion,
  selectIsLoading,
} from "../../redux/selector";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { ticketsReducer, ticketsSlice } from "../../redux/slice";
import { ticketsSaga } from "../../redux/saga";
import toastService from "utils/toast";
import { useTranslation } from "react-i18next";
import i18next, { changeLanguage } from "i18next";
import { themeActions } from "styles/theme/slice";
import { LANGUAGES, SUPPORTED_LANGUAGES, SupportedLanguage } from "utils/constants";
export default function Customization() {
  useInjectReducer({ key: ticketsSlice, reducer: ticketsReducer });
  useInjectSaga({ key: ticketsSlice, saga: ticketsSaga });

  const dispatch = useDispatch();
  const { id } = useParams();
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [questionForm, setQuestionForm] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const { t } = useTranslation();
  const [deleteTitle, setDeleteTitle] = React.useState("");
  const [deleteIndex, setDeleteIndex] = React.useState(-1);
  const [editIndex, setEditIndex] = React.useState(-1);
  const ticket = useSelector(selectCustomizeTicket);
  const question = useSelector(selectCustomizeTicketQuestion);
  const isLoading = useSelector(selectIsLoading);
  const [searchParams] = useSearchParams();
  const hl = searchParams.get("hl") || "en";
  useEffect(() => {
    if (!id) return;
    dispatch(actions.getCustomizeTicket(id));
  }, [id, dispatch]);

  const changeLanguage = (l: SupportedLanguage) => {
    i18next.changeLanguage(l);
    dispatch(themeActions.toggleDirection(l === "he" ? "rtl" : "ltr"));
  };
  useEffect(() => {
    if (SUPPORTED_LANGUAGES.includes(hl)) {
      if(i18next.language != hl) {
        changeLanguage(hl as SupportedLanguage)
      }
    }
  }, [hl, SUPPORTED_LANGUAGES]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          height: "100%",
          backgroundColor: "#F7F6F4",
          position: "relative",
        }}
      >
        <Header />
        <Box
          sx={{
            height: "100%",
            paddingBottom: "100px",
            overflow: "auto",
          }}
        >
          <Image />
          <Summary />
          <MidSection />
          <CustomizedQuestions
            onDelete={(index, body) => {
              setDeleteIndex(index);
              setConfirmDelete(true);
              setDeleteTitle(body);
            }}
            onEdit={(index) => {
              dispatch(actions.setForEditCustomizeQuestionForm(index));
              setEditIndex(index);
              setQuestionForm(true);
              setIsEdit(true);
            }}
            onAdd={() => {
              if (
                ticket.questions.length <
                ticket.ticket.customizations.maxQuestions
              ) {
                setQuestionForm(true);
              } else {
                toastService.error(
                  t(`TICKETS.ERRORS.MAX_QUESTIONS_ALLOWED`, {
                    number: ticket.ticket.customizations.maxQuestions,
                  })
                );
              }
            }}
          />
          <ActionButtons
            handleClose={() => {}}
            isLoading={isLoading}
            onConfirm={() => {
              if (!ticket.gameName.value || ticket.gameName.value.length == 0) {
                toastService.error(t(`TICKETS.ERRORS.GAME_NAME_IS_REQUIRED`));
              } else if (
                ticket.questions.length <=
                ticket.ticket.customizations.maxQuestions
              ) {
                dispatch(
                  actions.updateCustomizeTicket({
                    id: ticket._id.value,
                    callback: () => {
                      dispatch(actions.getCustomizeTicket(id || ""));
                    },
                  })
                );
              } else {
                toastService.error(
                  t(`TICKETS.ERRORS.MAX_QUESTIONS_ALLOWED`, {
                    number: ticket.ticket.customizations.maxQuestions,
                  })
                );
              }
            }}
            showCancel={false}
          />
        </Box>
        <Footer />
        <ConfirmDelete
          open={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          handleConfirm={() => {
            dispatch(
              actions.deleteCustomizeFormQuestion({ index: deleteIndex })
            );
            setConfirmDelete(false);
          }}
          title={deleteTitle}
        />
        <QuestionForm
          open={questionForm}
          handleClose={() => {
            dispatch(actions.clearCustomizeQuestionForm());
            setQuestionForm(false);
            setIsEdit(false);
            setEditIndex(-1);
          }}
          onConfirm={() => {
            if (question.answers.length == 0) {
              toastService.error(t("TICKETS.ERRORS.ADD_AT_LEAST_ONE_ANSWER"));
              return;
            }
            let check = question.answers.filter(
              (x) => x.title.length > 0
            ).length;
            if (check < 2) {
              toastService.error(t("QUESTIONS.ERRORS.SELECT_TWO_TITLE"));
              return;
            }
            check = question.answers.filter((x) => x.isCorrect).length;
            if (check == 0) {
              toastService.error(
                t("TICKETS.ERRORS.PLEASE_MARK_CORRECT_ANSWER")
              );
              return;
            }
            if (isEdit) {
              dispatch(actions.editCustomizeQuestion(editIndex));
            } else {
              dispatch(actions.addCustomizeQuestion());
            }
            setQuestionForm(false);
            setIsEdit(false);
            setEditIndex(-1);
          }}
        />
      </Box>
    </Box>
  );
}

import React, { useEffect } from "react";
import StationLinkTile from "../StationLinkTile";
import { useDispatch } from "react-redux";
import { SelectedStation } from "app/containers/Games/types";
import { actions as gameActions } from "app/containers/Games/redux/slice";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { selectSelectedQuestion } from "../GamesStations/redux/selector";
import { actions } from "../GamesStations/redux/slice";
import { selectDirection } from "styles/theme/slice";

export default function StationAndQuestions({
  i,
  station,
  disabled,
}: {
  readonly i: number;
  readonly station: SelectedStation;
  readonly disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const selectedQuestion = useSelector(selectSelectedQuestion);
 
  
  const direction = useSelector(selectDirection);

  useEffect(() => {
    if(selectedQuestion){
      dispatch(actions.getQuestions());
    }
  }, [dispatch, selectedQuestion]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <IconButton
        size="small"
        sx={{
      
          height: "30px",
          width: "30px",
          marginLeft: direction == 'ltr'  ? '-1px' : '5px',
          marginRight: direction == 'ltr'  ? '5px' : '-1px'
        }}
        onClick={() =>
          dispatch(
            actions.setSelectedQuestion(
              selectedQuestion === station.station?._id ? "" : station.station?._id
            )
          )
        }
      >
        {selectedQuestion === station.station?._id ? (
          <RemoveIcon />
        ) : (
          <AddIcon />
        )}
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: "8px",
        }}
      >
        <StationLinkTile
          selectedQuestion={selectedQuestion}
          index={i}
          disabled={disabled}
          station={station}
          handleRemoveClick={() =>
            dispatch(
                gameActions.removeFromSelectedStations(station.station?._id ?? "")
            )
          }
        />
       
      </Box>
    </Box>
  );
}

import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";



const InstructionModal = ({
  open,
  onClose,
  instructions = "Follow the instructions carefully to complete the task.",
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box  sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "24px",
          boxShadow: 24,
          outline: "none",
          width: '400px',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
        }}
      >
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {instructions}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleClose}
        >
          {t("COMMON.BUTTONS.CLOSE")}
        </Button>
      </Box>
    </Modal>
  );
};

export default InstructionModal;

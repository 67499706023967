import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReplyIcon from '@mui/icons-material/Reply';
import ShareForm from '../ShareForm';
import { MODES } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import OutlineButton from 'app/components/Buttons/OutlineButton';
import PrimaryButton from 'app/components/Buttons/PrimaryButton';
import { useParams } from 'react-router-dom';
import { selectDirection } from 'styles/theme/slice';
import { useSelector } from 'react-redux';
export default function Results({
    mode
}:{
    readonly mode: MODES;
}) {
    const [share, setShare] = useState(false)
    const { t } = useTranslation();
  const direction = useSelector(selectDirection);
    const { id } = useParams();
    const openResultsPageInNewTab = () => {
        window.open(`/results/${id}?hl=${localStorage.getItem("i18nextLng")}`, "_blank");
    }
    return (
        <Box 
            sx={{ 
            padding: "20px", 
            backgroundColor: "#F3F6F8",
            borderRadius: "8px",
            }}
        >
            <Typography
                sx={{
                    
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0em",
                    color: "#207DB9",
                    mb: "24px",
                }}
            >
                {t("TICKETS.FORM.RESULTS")}
            </Typography>
            <Box
            sx={{
                flexDirection: "row",
                display: "flex",
                gap: "10px",
                alignItems: "flex-end",
            }}
            >
            <PrimaryButton 
                startIcon={<CalendarMonthIcon 
                    sx={{
                        color: "#FFFFFF",
                        fontSize: "20px",
                    }}
                />}
                disabled={mode === MODES.NEW}
                onClick={openResultsPageInNewTab}
                sx={{
                    color: "#FFFFFF",
                    
                    fontSize: "14px !important",
                    fontWeight: 600,
                    lineHeight: "14px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    backgroundColor: "#E24399",
                }}
                label={t("TICKETS.FORM.SHOW_RESULTS_PAGE")}
            />
            <OutlineButton 
                startIcon={<ReplyIcon 
                    sx={{
                        color: "#E24399",
                        fontSize: "20px",
                        transform: "scaleX(-1)",
                    }}
                />}
                disabled={mode === MODES.NEW}
                sx={{
                    border: "2px solid #E24399",
                    color: "#E24399",
                    
                    fontSize: "14px !important",
                    fontWeight: 600,
                    lineHeight: "14px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    backgroundColor: "#FFFFFF",
                    '&:hover': {
                      border: '2px solid #E24399 !important',
                      opacity: 0.8,
                    },
                }}
                onClick={()=>setShare(true)}
                label={t("TICKETS.FORM.SHARE_RESULTS")}
            />
            </Box>
            <ShareForm 
                open={share}
                handleClose={()=>setShare(false)}
            />
        </Box>
    )
}

import InputField from "app/components/InputField";
import React from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "./TabPanel";
import { Box, FormControlLabel, Radio, Tab, Tabs, Typography } from "@mui/material";

interface OptionProps {
  saveInstanceHelp?: string;
saveCopyHelp?: string;
  disabled: boolean;
  value: number;
  onChange: (val) => void;
}

export default function Option({ disabled, value, onChange,saveInstanceHelp,saveCopyHelp }: OptionProps) {
  const { t } = useTranslation();
  return (
    <Box display='flex' flexDirection='column'>
      <Tabs
        value={value}
        onChange={(e, value) =>  onChange(value)} // dispatch is not defined
        variant="fullWidth"
        sx={{
          "& .MuiTabs-flexContainer": {
            gap: "14px",
          },
        }}
        style={{ borderBottom: "none" }}
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <Tab
          tabIndex={0}
          label={
            <FormControlLabel
              value={0}
              control={
                <Radio
                  size="small"
                  checked={value === 0}
                  sx={{
                    color: "#207DB9",
                    "&.Mui-checked": {
                      color: value === 0 ? "#ffffff" : "#207DB9",
                    },
                  }}
                />
              }
              label={
                <Typography
                  style={{
                    color: value === 0 ? "#ffffff" : "#5E6781",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                >
                  {t("COMMON.CONFIRM_OPTIONS.SAVE_INSTANCE")}
                </Typography>
              }
              sx={{
                marginRight: 0,
                marginBottom: 0,
              }}
            />
          }
          sx={{
            width: "100%",
            height: "44px",
            gap: "6px",
            borderRadius: "8px",
            backgroundColor: value === 0 ? "#E24399" : "#F3F6F8",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        />
        <Tab
          tabIndex={1}
          label={
            <FormControlLabel
              value={1}
              control={
                <Radio
                  size="small"
                  checked={value === 1}
                  sx={{
                    color: "#207DB9",
                    "&.Mui-checked": {
                      color: value === 1 ? "#ffffff" : "#207DB9",
                    },
                  }}
                />
              }
              label={
                <Typography
                  style={{
                    color: value === 1 ? "#ffffff" : "#5E6781",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    margin: 0
                  }}
                >
                  {t("COMMON.CONFIRM_OPTIONS.SAVE_ANOTHER_COPY")}
                </Typography>
              }
              sx={{
                marginRight: 0,
                marginBottom: 0,
              }}
            />
          }
          sx={{
            width: "100%",
            height: "44px",
            gap: "6px",
            borderRadius: "8px",
            backgroundColor: value === 1 ? "#E24399" : "#F3F6F8",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        />
        
      </Tabs>
      { value == 0 ?   <Typography style={{ height: 40, maxWidth: 410, padding: '4px', marginTop: '4px', fontSize: '16px', color: 'red'}}> {saveInstanceHelp}</Typography> : null}
      { value == 1 ?   <Typography  style={{height: 40, maxWidth: 410, padding: '4px', marginTop: '4px',fontSize: '16px', color: 'red'}}> {saveCopyHelp}</Typography> : null}
    </Box>
  );
}

import { Customer } from "app/containers/Customers/types";
import {
  FormElement,
  Metadata,
  OrderDirections,
  Pagination,
} from "app/containers/types";
import { User } from "app/types";
import { ASSET_TYPE, QUESTION_TYPES, TAsset } from "utils/constants";
export interface QuestionListResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Question[];
}
export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type QuestionsForm = {
  _id: string;
  type: FormElement;
  rightAnswerComment: FormElement;
  name: FormElement;
  body: FormElement;
  editorName: FormElement;
  link: FormElement;
  score: FormElement;
  showLink: FormElement;
  linkLabel: FormElement;
  asset: TAsset;
  organizationId: FormElement;
  isCustomQuestion: boolean;
};

export type QuestionsState = {
  form: QuestionsForm;
  list: Array<Question>;
  pagination: Pagination;
  loading: boolean;
  error: string;
  localAnswer: LocalAnswer;
  isUnsaved: boolean;
};

export type LocalAnswer = {
  single_select?: SingleMultiSelectAnswer[];
  multi_select?: SingleMultiSelectAnswer[];
  correlation?: CorelationAnswer[];
  mixed_letter?: MixedLetterAnswer[];
  free_text?: FreeTextAnswer[];
};

export type Answer =
  | SingleMultiSelectAnswer
  | SingleMultiSelectAnswer
  | CorelationAnswer
  | MixedLetterAnswer
  | FreeTextAnswer;

export const QuestionsState: QuestionsState = {
  list: [],
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 30,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  loading: false,
  form: {
    _id: "",
    type: {
      value: QUESTION_TYPES.SINGLE_SELECT,
      error: "",
    },
    rightAnswerComment: { value: "", error: "" },
    name: {
      value: "",
      error: "",
    },
    body: {
      value: "",
      error: "",
    },
    editorName: {
      value: "",
      error: "",
    },
    score: { value: "1", error: "" },
    link: { value: "", error: "" },
    showLink: { value: "false", error: "" },
    linkLabel: { value: "", error: "" },
    organizationId: { value: "", error: "" },
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.NONE,
    },
    isCustomQuestion: false,
  },
  localAnswer: {},
  error: "",
  isUnsaved: false,
};
export interface SingleMultiSelectAnswer {
  _id: string;
  title: string;
  isCorrect: boolean;
  order: number;
  isDelete: boolean;
  asset?: TAsset;
}

export interface CorelationAnswer {
  _id: string;
  left: string;
  right: string;
}

export interface MixedLetterAnswer {
  _id: string;
  word: string;
}

export interface FreeTextAnswer {
  _id: string;
  letters: FreeTextItemAnswer[];
  isPlotRTL?: boolean;
}
export interface FreeTextItemAnswer {
  _id: string;
  option1: string;
  isVisible: boolean;
  isDelete: boolean;
  option2: string;
}
export interface CustomizationTicket {
  _id: string;
  customer: Customer;
}
export interface QuestionCustomization {
  _id: string;
  ticket: CustomizationTicket;
}
export interface Question {
  _id: string;
  questionName: string;
  type: QUESTION_TYPES;
  name?: string;
  body: string;
  editorName: string;
  rightAnswerComment: string;
  score: number;
  link: string;
  linkLabel: string;
  showLink: boolean;
  organizationId: string;
  asset: TAsset;
  assetId: TAsset;
  customization?: QuestionCustomization;
  user: User;
  createdAt: string;
  updatedAt: string;
  answers: Array<Answer>;
  isCustomQuestion: boolean;
}

export type ContainerState = QuestionsState;

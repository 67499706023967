import { Box } from '@mui/material'
import React from 'react'
import logo from 'assets/logo.png'
import ContactItem, { Type } from './components/ContactItem'
import { MIFRA_DETAILS } from 'utils/constants'

const ContactInfo = MIFRA_DETAILS

export default function Footer() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "16px",
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "#26A8E0",
                gap: "12px"
            }}
        >
            <Box>
                <img alt="logo" src={logo} width="63px" height="22px" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                }}
            >
                {
                    ContactInfo?.map((item, i) => {
                        if(i > 1) return;
                        return (
                            <ContactItem 
                                type={item?.type}
                                text={item?.value}
                                link={item?.link}
                            />
                        )
                    })
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                }}
            >
                <ContactItem 
                    type={ContactInfo[2]?.type}
                    text={ContactInfo[2]?.value}
                    link={ContactInfo[2]?.link}
                />

                <ContactItem 
                    type={ContactInfo[3]?.type}
                    text={ContactInfo[3]?.value}
                    link={ContactInfo[2]?.link}
                />
            </Box>
        </Box>
    )
}

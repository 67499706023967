import React from "react";

const TermsOfUse = () => {
  return (
    <div className="page">
      <div
        id="terms-and-conditions"
        style={{ padding: "12px", margin: "auto", maxWidth: "1200px" }}
      >
        <header>
          <h1>Welcome to TwiGo!</h1>
          <p>
            Effective Date: 1 March 2023
            <br />
            Last Updated: 8 January 2024
          </p>
        </header>

        <section>
          <p>
            We’re excited to have you explore TwiGo, your gateway to gamified
            adventures. TwiGo combines the best of geolocation and QR-code-based
            games to create unforgettable experiences for players and event
            organizers. By downloading or using TwiGo, you agree to abide by
            these Terms of Use. If you do not agree, please do not use the app.
          </p>
        </section>

        <section>
          <h2>1. Agreement to Terms</h2>
          <p>
            These Terms of Use are a legally binding agreement between you (the
            user) and TwiGo. By using the app, you confirm that you have read,
            understood, and accepted these terms.
          </p>
        </section>

        <section>
          <h2>2. Description of Services</h2>
          <p>TwiGo is a mobile application and platform designed for:</p>
          <ul>
            <li>
              Players to participate in interactive, gamified challenges based
              on geolocation and QR codes.
            </li>
            <li>
              Organizers to design and manage custom events, integrate branding,
              and scale activities to fit their audience.
            </li>
          </ul>
          <p>
            Our services are available to users in Israel, the European Union,
            and the United States.
          </p>
        </section>

        <section>
          <h2>3. User Eligibility</h2>
          <p>To use TwiGo, you must:</p>
          <ul>
            <li>
              Be at least 13 years old or meet the age of digital consent in
              your country.
            </li>
            <li>Have the legal capacity to enter into this agreement.</li>
          </ul>
          <p>
            If you are under 18, you must have permission from a parent or
            guardian.
          </p>
        </section>

        <section>
          <h2>4. Your Obligations</h2>
          <p>When using TwiGo, you agree to:</p>
          <ul>
            <li>
              Provide accurate and up-to-date information when creating an
              account or registering for events.
            </li>
            <li>
              Comply with applicable laws and respect the intellectual property
              rights of others.
            </li>
            <li>
              Refrain from engaging in activities that harm other users, disrupt
              services, or violate these terms.
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Prohibited Activities</h2>
          <p>You may not:</p>
          <ul>
            <li>
              Reverse-engineer, decompile, or tamper with the TwiGo app or
              platform.
            </li>
            <li>
              Use TwiGo to promote illegal, harmful, or offensive content.
            </li>
            <li>Share your account credentials or impersonate another user.</li>
          </ul>
        </section>

        <section>
          <h2>6. Event Participation and Liability</h2>
          <p>
            TwiGo provides a platform for events but is not responsible for the
            safety, behavior, or outcomes of third-party-hosted events.
            Participation is at your own risk. Organizers must ensure compliance
            with local laws and event safety standards.
          </p>
        </section>

        <section>
          <h2>7. Intellectual Property</h2>
          <p>
            All content, designs, logos, and trademarks associated with TwiGo
            are the exclusive property of TwiGo. Users may not copy, reproduce,
            or distribute any part of the app or platform without prior written
            permission.
          </p>
        </section>

        <section>
          <h2>8. Modifications to Terms and Services</h2>
          <p>
            TwiGo reserves the right to update these Terms of Use and modify
            services at any time. Changes will be communicated through the app
            or website. Continued use of the app signifies your acceptance of
            the revised terms.
          </p>
        </section>

        <section>
          <h2>9. Disclaimer of Warranties</h2>
          <p>
            TwiGo is provided "as is" without warranties of any kind. We do not
            guarantee uninterrupted access, error-free functionality, or that
            the app will meet your specific needs.
          </p>
        </section>

        <section>
          <h2>10. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, TwiGo is not liable for
            indirect, incidental, or consequential damages resulting from the
            use or inability to use the app, even if we have been advised of
            potential risks.
          </p>
        </section>

        <section>
          <h2>11. Governing Law</h2>
          <p>
            These Terms are governed by the laws of Israel. Any disputes arising
            from these terms will be resolved under the exclusive jurisdiction
            of Israeli courts.
          </p>
        </section>

        <footer>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or require support, please reach out to
            us:
            <br />
            Email:{" "}
            <a href="mailto:support@twigoapp.com">support@twigoapp.com</a>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default TermsOfUse;


import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
  return {
    error: {
      border: '1px solid rgb(211, 47, 47) !important',
    },
    root: {
      border: '1px solid #86bce3',
      borderRadius: '4px',
      background: '#fff',
      direction: theme.direction === 'rtl' ? 'rtl' : 'ltr', // Dynamically set the direction
      textAlign: theme.direction === 'rtl' ? 'right' : 'left', // Dynamic text alignment
      '& .mdxeditor-toolbar': {
        zIndex: 'auto',
        justifyContent: theme.direction === 'rtl' ?  'flex-end' : 'flex-start',
      },
      '& .mdxeditor-root-contenteditable': {
          height: '295px',
          overflowY: 'auto',
      },
      '& [role="textbox"]': {
        height: '100%',
      },
      '& [dir="rtl"]': {
        textAlign: 'right',
      },
      '& [dir="ltr"]': {
        textAlign: 'left',
      },
    },
    toolbarWrap: {
     flexDirection: 'row', display: 'flex', border: '1px solid #ccc' , alignItems: 'flex-start',
    },
    editorContainer: {
      height: '337px', // Fixed height
      overflowY: 'auto', // Enable vertical scrolling
      border: '1px solid #ccc', // Optional: add a border for better visibility
      padding: '10px', // Optional: add some padding
    },
  
  }
});

import React, { useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { actions } from 'app/containers/Results/redux/slice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCurrentTab } from 'app/containers/Results/redux/selector';

const CustomButtonGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

   const currentTab = useSelector(selectCurrentTab)
  const handleButtonClick = (buttonKey: string) => {
    dispatch(actions.setCurrentTab(buttonKey))
  };

  return (
    <Grid2
      container
      sx={{
        background: "#26A8E0",
        borderRadius: "0px 0px 32px 32px",
        paddingY: "8px",
        paddingX: "12px",
      }}
    >
      <div
        style={{
          width: '100%',
          backgroundColor: '#4ba3e8',
          borderRadius: '15px',
        }}
      >
        <h3 style={{ color: 'white' }}>{t('RESULTS.TITLE')}</h3>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          sx={{
            backgroundColor: 'white',
            borderRadius: '20px',
            padding: '5px',
            width: '100%',
            '& .MuiButton-root': {
              borderRadius: '20px',
              outline: 'none', // Remove default focus outline
            },
            '& > *': {
              borderRight: 'none !important', // Fixes the border issue between buttons
            },
          }}
        >
          <Button
            onClick={() => handleButtonClick('gameDetails')}
            sx={{
              flex: 1,
              backgroundColor:
                currentTab === 'gameDetails' ? '#d64fa1' : 'transparent',
              color: currentTab === 'gameDetails' ? 'white' : '#d64fa1',
              textTransform: 'none',
              fontWeight: 'bold',
              '&:hover, &:focus, &:active': {
                backgroundColor: '#d64fa1',
                color: 'white',
              },
            }}
          >
            {t('RESULTS.GAME_DETAILS')}
          </Button>
          <Button
            onClick={() => handleButtonClick('groups')}
            sx={{
              flex: 1,
              backgroundColor:
                currentTab === 'groups' ? '#d64fa1' : 'transparent',
              color: currentTab === 'groups' ? 'white' : '#d64fa1',
              textTransform: 'none',
              fontWeight: 'bold',
              '&:hover, &:focus, &:active': {
                backgroundColor: '#d64fa1',
                color: 'white',
              },
            }}
          >
            {t('RESULTS.GROUPS')}
          </Button>
          <Button
            onClick={() => handleButtonClick('photos')}
            sx={{
              flex: 1,
              backgroundColor:
                currentTab === 'photos' ? '#d64fa1' : 'transparent',
              color: currentTab === 'photos' ? 'white' : '#d64fa1',
              textTransform: 'none',
              fontWeight: 'bold',
              '&:hover, &:focus, &:active': {
                backgroundColor: '#d64fa1',
                color: 'white',
              },
            }}
          >
            {t('RESULTS.PHOTOS')}
          </Button>
        </ButtonGroup>
      </div>
    </Grid2>
  );
};

export default CustomButtonGroup;

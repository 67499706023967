import { Customer } from "app/containers/Customers/types";
import { OrderDirections, Pagination } from "app/containers/types";

export type CustomerSelectState = {
  customers: Customer[];
  pagination: Pagination;
  isLoading: boolean;
};

export const CustomersState: CustomerSelectState = {
  customers: [],
    isLoading: false,
    pagination: {
      filters: {
        isActive: "true",
      },
      search: "",
      page: 1,
      perPage: 40,
      orderBy: "createdAt",
      orderDirection: OrderDirections.DESC,
      total: 0,
      totalPage: 0,
    }
};

export type ContainerState = CustomerSelectState;
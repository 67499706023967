import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, User } from "../types";
import { LoginResponse } from "app/containers/Auth/containers/LoginPage/types";
import {
  Organization,
  OrganizationListResponse,
} from "app/containers/Organizations/types";
import { OrderDirections, Query } from "app/containers/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
export const setCurrentOrganization = createAction("SET_CURRENT_SUCCESS");

const defaultOrganization = localStorage.getItem("organization");

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  tokenType: "",
  roleType: "",
  expiresIn: 0,
  accessToken: localStorage.getItem("accessToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  user: null,
  isLoading: false,
  message: null,
  organizations: [],
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 30,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  organization: defaultOrganization ? JSON.parse(defaultOrganization) : null,
  isLoadingOrganizations: false,
  isEnableOrganization: false,
};

const formSlice = createSlice({
  name: "authState",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, (state) => {
      state.accessToken = "";
    });
  },
  reducers: {
    setCredentials(state, action: PayloadAction<LoginResponse>) {
      state.tokenType = action.payload.tokenType;
      state.roleType = action.payload.roleType;
      state.expiresIn = action.payload.expiresIn;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoading = false;
      state.message = null;
    },
    getUser(state) {
      state.isLoading = true;
      state.message = "AUTH.LOADING.USER";
    },
    getSetEnableOrganization(state, action: PayloadAction<boolean>) {
      state.isEnableOrganization = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isLoading = false;
      state.message = null;
    },
    refreshToken(state, action: PayloadAction<string>) {
      state.isLoading = true;
      state.message = "AUTH.LOADING.REFRESH";
    },
    getOrganizations: (state, action: PayloadAction<Query>) => {
      state.isLoadingOrganizations = true;
    },
    setOrganizations: (
      state,
      action: PayloadAction<OrganizationListResponse>
    ) => {
      state.pagination = action.payload._metadata.pagination;
      if (state.pagination.page == 1) {
        state.organizations = action.payload.data;
      } else {
        state.organizations = [...state.organizations, ...action.payload.data];
      }
      state.isLoadingOrganizations = false;
    },
    getOrganizationError: (state) => {
      state.isLoadingOrganizations = false;
    },
    setToCurrentOrganization: (state, action: PayloadAction<string>) => {
      const orgId = action.payload;
      if (orgId) {
        state.organization = {
          label: '',
          value: orgId,
        };
      }
    },
    setOrganization: (
      state,
      action: PayloadAction<{ label: string; value: string }>
    ) => {
      state.organization = action.payload;
    },
    logout() {},
    logoutSuccess(state) {
      state = initialState;
    },
  },
});

export const { actions, reducer: authReducer, name: authSlice } = formSlice;

import useWindowDimensions from "./useWindowDimensions";

const MIN_WIDTH = 1024;
const useIsMobileScreen = (minWidth = MIN_WIDTH) => {
  const { width } = useWindowDimensions();

  const MOBILE_RESPONSIVE_PAGES = [
    "/tickets/customization/:id",
    "/results/:id",
    "/pages/" // Matches any path under /pages/
  ];

  const pathname = window?.location?.pathname || '';

  // Function to check if the pathname matches dynamic paths
  const matchesDynamicPath = (path) => {
    const regex = new RegExp(`^${path.replace(/:[^\s/]+/g, '[^/]+')}$`);
    return regex.test(pathname);
  };

  // Check if the pathname matches any of the mobile-responsive paths (static or dynamic)
  const isMobileScreen = MOBILE_RESPONSIVE_PAGES.some(path =>
    pathname.startsWith(path) || matchesDynamicPath(path)
  );

  const unsupportedScreen = width && width <= minWidth && !isMobileScreen;

  return { isMobileScreen, unsupportedScreen };
};

export default useIsMobileScreen;

import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import { ASSET_TYPE, MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";
import {
  selectGame,
  selectIsLoading,
  selectIsUnsaved,
  selectSelectedStations,
} from "../../redux/selector";
import toastService from "utils/toast";
import { useTranslation } from "react-i18next";
import { validateGame } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import { actions as actionsOrganization } from "app/redux/slice";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import { Box } from "@mui/material";
import Settings from "./components/Settings";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmDialog from "app/components/ConfirmDialog";
import Option from "app/components/ConfirmDialog/Component/Option";
import { truncate } from "utils/helpers";
import useOrgValidator from "app/containers/Auth/containers/component/OrgValidator";
import NavigationConfirmDialog from "app/components/NavigationConfirmDialog";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import TestGameModal from "./components/TestGameModal";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const isUnsaved = useSelector(selectIsUnsaved);
  const { id } = useParams();
  const isLoading = useSelector(selectIsLoading);
  const selectedStations = useSelector(selectSelectedStations);
  const game = useSelector(selectGame);
  useOrgValidator(game.organizationId.value);
  const organization = useSelector(selectOrganization);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(0);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getGame({ id: id, duplicate: mode === MODES.DUPLICATE }));
  }, [mode, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);

  const styles = useStyles();
  const navigate = useNavigate();
  const openTestGameTicket = () => {
    dispatch(actions.openTestGameTicket());
  };

  const handleCancelBtn = () => {
    if (isUnsaved) {
      setShowConfirm(true);
      return;
    }

    dispatch(
      actions.resetForm({
        callback: () => {
          navigate("/app/games");
        },
      })
    );
  };

  const onSubmit = (isDuplicate: boolean) => {
    const errors = validateGame(game);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      toastService.error(t("GAMES.ERRORS.FORM_HAS_ERRORS"));
      return;
    }
    if (selectedStations?.length < 1) {
      toastService.error(t("GAMES.ERRORS.PLEASE_SELECT_AT_LEAST_ONE_STATION"));
      return;
    }
    dispatch(
      actions.createGame({
        game: {
          name: `${isDuplicate ? "Copy Of " : ""} ${game.name.value}`,
          emailLanguage: game.emailLanguage.value,
          background: game.backgroundId.value,
          gameBanner: game.gameBannerId.value,
          groupInstructionText: game.groupInstructionText.value,
          groupInstructionPDF: game.groupInstructionPDFId.value,
          startGameText: game.startGameText.value,
          endGameText: game.endGameText.value,
          endGameAsset:
            game.endGameAssetType.value == ASSET_TYPE.NONE
              ? null
              : game.endGameAssetId.value,
          startGameAsset:
            game.startGameAssetType.value == ASSET_TYPE.NONE
              ? null
              : game.startGameAssetId.value,
          welcomeScreenSettings: {
            showStartPoint:
              game.showStartPoint.value === "true" || game.showStartPoint.value
                ? true
                : false,
            showEndPoint:
              game.showEndPoint.value === "true" || game.showEndPoint.value
                ? true
                : false,
          },
          courseSettings: {
            course: game.course.value,
            startIsIdentical: game.startIsIdentical.value,
            endIsIdentical: game.endIsIdentical.value,
          },
          gameStations: selectedStations?.map((gS, i) => ({
            _id: gS.id,
            station: gS.station._id,
            group: gS.number,
            sortingOrder: i,
          })),
          gameSettings: {
            canSkip: game?.canSkip?.value,
            canSkipAfter: game?.canSkipAfter?.value,
            numberOfMistakesTillReveal: game?.numberOfMistakesTillReveal?.value,
            delayAfterMistake: game?.delayAfterMistake?.value,
            numberOfMistakesTillDelay: game?.numberOfMistakesTillDelay?.value,
          },
          isActive: game.isActive.value,
          organization: organization?.value ?? "",
        },
        callback: (id: string) => {
          setTimeout(() => {
            navigate(`/app/games/${id}/edit?action=create`);
          }, 1000);
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateGame(game);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      toastService.error(t("GAMES.ERRORS.FORM_HAS_ERRORS"));
      return;
    }
    if (selectedStations?.length < 1) {
      toastService.error(t("GAMES.ERRORS.PLEASE_SELECT_AT_LEAST_ONE_STATION"));
      return;
    }
    dispatch(
      actions.updateGame({
        game: {
          _id: game._id.value,
          name: game.name.value,
          emailLanguage: game.emailLanguage.value,
          background: game.backgroundId.value,
          gameBanner: game.gameBannerId.value,
          groupInstructionText: game.groupInstructionText.value,
          groupInstructionPDF: game.groupInstructionPDFId.value,
          startGameText: game.startGameText.value,
          endGameText: game.endGameText.value,
          endGameAsset:
            game.endGameAssetType.value == ASSET_TYPE.NONE
              ? null
              : game.endGameAssetId.value,
          startGameAsset:
            game.startGameAssetType.value == ASSET_TYPE.NONE
              ? null
              : game.startGameAssetId.value,
          welcomeScreenSettings: {
            showStartPoint:
              game.showStartPoint.value === "true" || game.showStartPoint.value
                ? true
                : false,
            showEndPoint:
              game.showEndPoint.value === "true" || game.showEndPoint.value
                ? true
                : false,
          },
          courseSettings: {
            course: game.course.value,
            startIsIdentical: game.startIsIdentical.value,
            endIsIdentical: game.endIsIdentical.value,
          },
          gameStations: selectedStations?.map((gS, i) => ({
            _id: gS.id,
            station: gS.station._id,
            group: gS.number,
            sortingOrder: i,
          })),
          gameSettings: {
            canSkip: game?.canSkip?.value,
            canSkipAfter: game?.canSkipAfter?.value,
            numberOfMistakesTillReveal: game?.numberOfMistakesTillReveal?.value,
            delayAfterMistake: game?.delayAfterMistake?.value,
            numberOfMistakesTillDelay: game?.numberOfMistakesTillDelay?.value,
          },
          isActive: game.isActive.value,
          organization: organization?.value ?? "",
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/games");
              },
            })
          );
        },
      })
    );
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit(false);
      return;
    }
    if (mode === MODES.EDIT) {
      if (isUnsaved) {
        setShowSaveConfirm(true);
      } else {
        onUpdate();
      }
      return;
    }
    navigate(`/app/games/${id}/edit`);
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      onSubmit(true);
    }
    setShowSaveConfirm(false);
    setSaveOption(0);
  };
  const handelConfirm = () => {
    setShowConfirm(false);
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit(false);
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
  };

  useEffect(() => {
    dispatch(actionsOrganization.getSetEnableOrganization(true));

    return () => {
      dispatch(actionsOrganization.getSetEnableOrganization(false));
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);

  return (
    <>
      <Box className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          title={
            game.name.value
              ? truncate(game.name.value, 60)
              : generatePageTitle(Modules.GAMES, mode)
          }
          onBackClick={handleCancelBtn}
          headerTextStyle={{maxWidth: '40vw'}}
        />
        <Box flex={1} justifyContent={'flex-end'} display={"flex"} flexDirection={"row"}>
          {game.defaultTicket.testPassword &&
            (mode == MODES.EDIT || mode == MODES.VIEW) && (
              <PrimaryButton
                label={t("GAMES.FORM.LABELS.TEST_GAME")}
                onClick={openTestGameTicket}
                sx={{
                  width: "119px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  ".MuiButton-startIcon": {
                    marginRight: 0,
                  },
                }}
                startIcon={<MobileFriendlyIcon />}
              />
            )}
          
            <PrimaryButton
              label={t("GAMES.SETTINGS.LABEL")}
              onClick={() => setOpenSettings(true)}
              sx={{
                width: "119px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              startIcon={<SettingsIcon />}
            />
          

          <ActionButtons
            handleCancelBtn={handleCancelBtn}
            handleConfirmBtn={handleConfirmBtn}
            mode={mode}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      {/**Game Details Form */}
      <Form mode={mode} />
      <div style={{ height: "20px" }} />

      <Settings
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
      />
      <NavigationConfirmDialog
        title={t("COMMON.SCREEN_NAME.GAME")}
        isUnsaved={isUnsaved}
        onConfirm={handelConfirm}
      ></NavigationConfirmDialog>
      <TestGameModal />
      <ConfirmDialog
        open={showConfirm}
        handleClose={(val) => {
          if(val) {
            setShowConfirm(false);
            dispatch(
              actions.resetForm({
                callback: () => {
                  navigate("/app/games");
                },
              })
            );
          }else{
            setShowConfirm(false);
          }
         
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.GAME"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={() => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            setSaveOption(e);
          }}
          saveInstanceHelp={t("COMMON.CONFIRM_OPTIONS.GAMES.SAVE_INSTANCE_HELP")}
          saveCopyHelp={t("COMMON.CONFIRM_OPTIONS.GAMES.SAVE_ANOTHER_COPY_HELP")}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;

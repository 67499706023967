import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import InfoIcon from '@mui/icons-material/Info';
import MessageCard from '../MessageCard';
import MediaUploader from 'app/components/MediaUploader';
import { useTranslation } from 'react-i18next';
import { selectGame } from 'app/containers/Games/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Games/redux/slice';
import { MediaTypes } from 'app/containers/types';
import { selectDirection } from 'styles/theme/slice';
import { ASSET_TYPE } from 'utils/constants';

export default function EndGame({
    disabled
}:{
    disabled?: boolean
}) {
    const { t } = useTranslation()
  const direction = useSelector(selectDirection);
    const game = useSelector(selectGame)
    const dispatch = useDispatch()

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.setFormValues({ key: e.target.name, value: e.target.value }))
    }
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Typography
                sx={{
                    
                    fontSize: "14px",
                    fontWeight: 600,
                    letterSpacing: "0em",
                    color: "#207DB9",
                    textTransform: "uppercase",
                    textAlign: "center",
                }}
            >
                {t("GAMES.FORM.END_GAME")}
                <Tooltip title={t("GAMES.FORM.LABELS.END_GAME_TOOLTIP")}>
                    <InfoIcon 
                        sx={{
                            fontSize: "18px",
                            marginLeft: "6px",
                            color: "#3C3C43"
                        }}
                    />
                </Tooltip>
            </Typography>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "12px",
                }}
            >
                <MessageCard 
                    disabled={disabled}
                    type="secondary"
                    label={t("GAMES.FORM.END_MESSAGE")}
                    value={game.endGameText.value}
                    onChange={onChange}
                    error={game.endGameText.error ? t(game.endGameText.error) : ""}
                    name="endGameText"
                    maxLength={512}
                />
                <MediaUploader 
                    onCheck={(value) => {
                        dispatch(actions.setFormValues({ key: "endGameAssetType", value }))
                        dispatch(actions.setFormValues({ key: "endGameAssetUrl", value: "" }))
                    }}
                    showBtn={false}
                    primaryColor='#207DB9'
                    secondaryColor='#EDF4F8'
                    type={game.endGameAssetType.value || MediaTypes.NONE}
                    caption={game.endGameAssetType.value === MediaTypes.VIDEO ? t("GAMES.FORM.LABELS.GAME_VIDEO_CAPTION") : t("GAMES.FORM.LABELS.GAME_IMAGE_CAPTION")}
                    title={game.endGameAssetType.value === MediaTypes.VIDEO ? t("GAMES.FORM.LABELS.END_GAME_VIDEO") : t("GAMES.FORM.LABELS.END_GAME_IMAGE")}
                    src={game.endGameAssetUrl.value}
                    error={game.endGameAssetUrl.error ? t(game.endGameAssetUrl.error) : ""}
                    onSuccess={(img)=>{
                        dispatch(actions.setFormValues({ key: "endGameAssetUrl", value: img?.url }))
                        dispatch(actions.setFormValues({ key: "endGameAssetId", value: img?._id }))
                    }}
                    checked={game?.endGameAssetType?.value || ASSET_TYPE.NONE}
                    disabled={disabled}
                    fallbackWidth="354px"
                    fallbackHeight="185px"
                    width="354px"
                    height='185px'
                
                />
            </Box>
        </Box>
    )
}

import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";
import { actions as actionsOrganization } from "app/redux/slice";
import {
  selectEditor,
  selectIsLoading,
  selectIsUnsaved,
} from "../../redux/selector";
import { validateEditor } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import Option from "app/components/ConfirmDialog/Component/Option";
import { Box } from "@mui/material";
import useOrgValidator from "app/containers/Auth/containers/component/OrgValidator";
import NavigationConfirmDialog from "app/components/NavigationConfirmDialog";
import { RoleTypes } from "app/types";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useSelector(selectEditor);
  useOrgValidator(editor.organizationId.value);
  const organizationId = useSelector(selectOrganization);
  const isLoading = useSelector(selectIsLoading);
  const isUnsaved = useSelector(selectIsUnsaved);

  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
 

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getEditor(id));
  }, [id, mode, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, [dispatch]);

  const onSubmit = () => {
    const errors = validateEditor(editor);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createEditor({
        editor: {
          email: editor.email.value,
          fullName: editor.fullName.value,
          mobileNumber: editor.mobileNumber.value.replace("-", ""),
          departmentName: editor.departmentName.value,
          organizationId: organizationId?.value,
          _id: editor._id.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/editors");
              },
            })
          );
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateEditor(editor);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateEditor({
        editor: {
          email: editor.email.value,
          fullName: editor.fullName.value,
          mobileNumber: editor.mobileNumber.value.replace("-", ""),
          departmentName: editor.departmentName.value,
          _id: editor._id.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/editors");
              },
            })
          );
        },
      })
    );
  };
  const isEditable =  editor.role == RoleTypes.EDITOR
  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
    navigate(`/app/editors/${id}/edit`);
  };

  const handleCancelBtn = () => {
    if (isUnsaved) {
      setShowConfirm(true);
      return;
    }
    navigate("/app/editors");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
  };
  useEffect(() => {
    dispatch(actionsOrganization.getSetEnableOrganization(true));

    return () => {
      dispatch(actionsOrganization.getSetEnableOrganization(false));
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);



  return (
    <>
      <div className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          onBackClick={handleCancelBtn}
          title={generatePageTitle(Modules.EDITORS, mode)}
        />
        <Box>
          <ActionButtons
            handleCancelBtn={handleCancelBtn}
            handleConfirmBtn={handleConfirmBtn}
            mode={mode}
            isLoading={isLoading}
            disable={ !!id && !isEditable}
          />
        </Box>
      </div>
      {/**Editor Details Form */}
      <Form mode={mode} />
      <NavigationConfirmDialog
        title={t("COMMON.SCREEN_NAME.GAME")}
        isUnsaved={isUnsaved}
        onConfirm={handelConfirm}
      ></NavigationConfirmDialog>
      <ConfirmDialog
        open={showConfirm}
        handleClose={(val) => {
          if(val) {
            setShowConfirm(false);
            dispatch(
              actions.resetForm({
                callback: () => {
                  navigate("/app/editors");
                },
              })
            );
          }else{
            setShowConfirm(false);
          }
         
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.EDITOR"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
    
    </>
  );
};

export default Details;

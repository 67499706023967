import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./components/Form";
import QuestionTypes from "./components/QuestionTypes";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import BreadCrumb from "app/components/BreadCrumb";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions as actionsOrganization } from "app/redux/slice";
import {
  selectAnswer,
  selectForm,
  selectIsLoading,
  selectIsUnsaved,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { validateQuestion } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import Option from "app/components/ConfirmDialog/Component/Option";
import { Box } from "@mui/material";
import { useStyles } from "./style";
import useOrgValidator from "app/containers/Auth/containers/component/OrgValidator";
import NavigationConfirmDialog from "app/components/NavigationConfirmDialog";
import InstructionModal from "app/components/InstructionModal";
type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const [duplicatePopup, setDuplicatePopup] = useState(false)
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  useOrgValidator(form.organizationId.value);
  const organization = useSelector(selectOrganization);
  const isUnsaved = useSelector(selectIsUnsaved);
  const loading = useSelector(selectIsLoading);
  const answers = useSelector(selectAnswer);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(0);
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    if (mode == MODES.DUPLICATE) {
      dispatch(actions.setFormClearId());
    }
    dispatch(actions.getQuestion({ id, duplicate: mode === MODES.DUPLICATE }));
  }, [mode, id, dispatch]);

  useEffect(() => {
    dispatch(
      actions.setFormValues({
        key: "organizationId",
        value: organization?.value,
      })
    );
    return () => {};
  }, [organization]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);

  const onSubmit = () => {
    const errors = validateQuestion(form, answers);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createQuestion({
        callback: () => {
          if(mode === MODES.DUPLICATE) {
            setDuplicatePopup(true)
          }else{
            dispatch(
              actions.resetForm({
                callback: () => {
                  navigate("/app/questions");
                },
              })
            );
          }
         
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateQuestion(form, answers);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateQuestion({
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/questions");
              },
            })
          );
        },
      })
    );
  };
  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      if (isUnsaved) {
        setShowSaveConfirm(true);
      } else {
        onUpdate();
      }

      return;
    }
    navigate(`/app/questions/${id}/edit`);
  };

  const handleCancelBtn = () => {
    if (isUnsaved) {
      setShowConfirm(true);
      return;
    }

    dispatch(
      actions.resetForm({
        callback: () => {
          navigate("/app/questions");
        },
      })
    );
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      dispatch(
        actions.setFormValues({
          key: "body",
          value: "Copy Of " + form.body.value,
        })
      );
      setTimeout(() => {
        onSubmit();
      }, 500);
    }
    setShowSaveConfirm(false);
    setSaveOption(0);
  };
  useEffect(() => {
    dispatch(actionsOrganization.getSetEnableOrganization(true));

    return () => {
      dispatch(actionsOrganization.getSetEnableOrganization(false));
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);
  return (
    <>
      <div className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          title={generatePageTitle(Modules.QUESTIONS, mode)}
          onBackClick={handleCancelBtn}
        />
        <Box>
          <ActionButtons
            mode={mode}
            handleCancelBtn={handleCancelBtn}
            handleConfirmBtn={handleConfirmBtn}
            isLoading={loading}
          />
        </Box>
      </div>
      <InstructionModal instructions={t('COMMON.CONFIRM_OPTIONS.QUESTIONS.SAVE_ANOTHER_COPY_HELP')} open={duplicatePopup} onClose={()=> {
        setDuplicatePopup(false)
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/questions");
              },
            })
          );

        }}/>
      {/**Question Details Form */}
      <Form mode={mode} />
      <div style={{ height: "32px" }} />
      {/**Questions Table */}
      <QuestionTypes mode={mode} />
      <NavigationConfirmDialog
        title={t("COMMON.SCREEN_NAME.GAME")}
        isUnsaved={isUnsaved}
        onConfirm={handelConfirm}
      ></NavigationConfirmDialog>
      <ConfirmDialog
        open={showConfirm}
        handleClose={(val) => {
          if(val) {
            setShowConfirm(false);
            dispatch(
              actions.resetForm({
                callback: () => {
                    navigate("/app/questions");
                },
              })
            );
          }else{
            setShowConfirm(false);
          }
         
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.QUESTION"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={() => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            setSaveOption(e);
          }}
          saveInstanceHelp={t("COMMON.CONFIRM_OPTIONS.QUESTIONS.SAVE_INSTANCE_HELP")}
          saveCopyHelp={t("COMMON.CONFIRM_OPTIONS.QUESTIONS.SAVE_ANOTHER_COPY_HELP")}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;

import { Box, Typography } from "@mui/material";
import OutlineButton from "app/components/Buttons/OutlineButton";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import React from "react";
import { selectIsLoading } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slice";
import { CreateAssetResponse } from "../types";
import { MediaTypes } from "app/containers/types";
import { useTranslation } from "react-i18next";

interface UploaderLayoutProps {
  showSaveAndClose: boolean;
  onCancel: () => void;
  type:
    | "image"
    | "video"
    | "pdf"
    | "background"
    | "banner"
    | "image/video"
    | "none";
  children: React.ReactNode;
  onUpload: (url?: CreateAssetResponse) => void;
  acceptedFiles?: File[];
  title?: string;
}

export default function UploaderLayout({
  showSaveAndClose,
  onCancel,
  type,
  children,
  onUpload,
  acceptedFiles = [],
  title,
}: UploaderLayoutProps) {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSuccess = (img: CreateAssetResponse) => {
    onUpload(img);
    onCancel();
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        padding: { xs: "8px 8px", sm: "15px 30px" },
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "29px",
          letterSpacing: "0em",
          color: "#207DB9",
        }}
      >
        {title}
      </Typography>
      {children}
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <OutlineButton
          label={t("COMMON.BUTTONS.CANCEL")}
          onClick={onCancel}
          disabled={isLoading}
          sx={{ width: { sx: "70px" } }}
        />
        {!acceptedFiles.length && showSaveAndClose  === true? (
          <PrimaryButton
            isLoading={isLoading}
            label={t("COMMON.BUTTONS.SAVE")}
            sx={{ width: { sx: "70px" } }}
            onClick={() => {
              onSuccess?.({
                _id: '',
                url: '',
                createdAt: "",
                updatedAt: "",
                type: "",
                mimeType: "",
                fileName: "",
                filePath: ""
              })
              onCancel()
            }
              
            }
          />
        ) : (
          <PrimaryButton
            isLoading={isLoading}
            label={t("COMMON.BUTTONS.UPLOAD")}
            disabled={acceptedFiles?.length < 1}
            sx={{ width: { sx: "70px" } }}
            onClick={() =>
              dispatch(
                actions.uploadMedia({
                  media: {
                    file: acceptedFiles?.[0] as File,
                    type:
                      type == MediaTypes.IMAGE_VIDEO
                        ? acceptedFiles?.[0].type == "video/mp4"
                          ? "video"
                          : "image"
                        : type,
                    mimeType: acceptedFiles?.[0]?.type as string,
                    name: acceptedFiles?.[0]?.name as string,
                  },
                  callback: onSuccess,
                })
              )
            }
          />
        )}
      </Box>
    </Box>
  );
}

import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import React from "react";
import logo from "assets/logo.png";
import Stack from "./components/Stack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dateFormatter } from "utils/helpers";
import { selectTicket } from "app/containers/Results/redux/selector";
import CustomButtonGroup from "./components/ButtonGroup";
type Props = {
  isMobile: boolean;
};

export default function Header({ isMobile }: Props) {
  const { t } = useTranslation();
  const ticket = useSelector(selectTicket);

  const handleCopy = () => {
    if (navigator?.clipboard) {
      const currentURL = window.location.href;

      navigator.clipboard.writeText(currentURL).then(
        function () {
          console.log("Text successfully copied to clipboard");
        },
        function (err) {
          console.error("Could not copy text: ", err);
        }
      );
    }
  };
  if (isMobile) {
    return (
      <CustomButtonGroup />)
  }
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        background: "#26A8E0",
        borderRadius: "0px 0px 32px 32px",
        paddingY: "24px",
        paddingX: "44px",
      }}
    >
      <Grid2 md={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Box>
            <img src={logo} width="145px" height="50px" alt="logo" />
          </Box>
          <PrimaryButton
            label={t("RESULTS.BUTTONS.COPY_LINK")}
            onClick={handleCopy}
            sx={{
              backgroundColor: "#E24399",
              textTransform: "uppercase",
              fontSize: "16px",
              fontWeight: 600,
            }}
          />
        </Box>
      </Grid2>
      <Grid2 md={7}>
        <Box>
          <Grid2
            container
            spacing={3}
            sx={{
              marginTop: "24px",
            }}
          >
            {ticket?.customer?.name && (
              <Grid2 md={4}>
                <Stack
                  label={"CUSTOMER_NAME"}
                  value={ticket?.customer?.name || ""}
                />
              </Grid2>
            )}
            <Grid2 md={4}>
              <Stack label={"GAME_NAME"} value={ticket?.game?.name || ""} />
            </Grid2>
            <Grid2 md={4}>
              <Stack
                label={"FROM_DATE"}
                value={dateFormatter(ticket?.startDate)}
              />
            </Grid2>
            <Grid2 md={4}>
              <Stack label={"TO_DATE"} value={dateFormatter(ticket?.endDate)} />
            </Grid2>
            <Grid2 md={4}>
              <Stack
                label={"CREATED_DATE"}
                value={dateFormatter(ticket?.createdAt)}
              />
            </Grid2>
            <Grid2 md={4}>
              <Stack
                label={"NUMBER_OF_DEVICES"}
                value={String(ticket?.noOfDevices) || ""}
              />
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
      <Grid2 md={5}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {ticket?.game?.startGameAsset?.url ? (
            <>
              {ticket?.game?.startGameAsset?.type === "video" ? (
                <video
                  style={{ height: '250px', objectFit: "contain", maxWidth: '100%',
                    maxHeight: '100%', }}
                  controls
                  src={ticket?.game?.startGameAsset?.url}
                />
              ) : (
                <img
                  style={{ objectFit: "contain", width: "auto" , maxWidth: '100%',
                    maxHeight: '100%',}}
                  src={ticket?.game?.startGameAsset?.url}
                  height={250}
                  alt="game"
                />
              )}
            </>
          ) : null}
        </Box>
      </Grid2>
    </Grid2>
  );
}

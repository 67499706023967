import { Ticket } from "app/containers/Tickets/types";
import {
  FormElement,
  Metadata,
  OrderDirections,
  Pagination,
} from "app/containers/types";
import { ResultAnswers } from "./results";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export interface ResultPageData {
    ticket: Ticket,
    results:  ResultsListItemType[]
  
}
export interface ResultPageResponseResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: ResultPageData
}
export interface ResultListResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: ResultsListItemType[];
}

export type ResultsListItemType = {
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  groupName: string;
  game: { name: string; _id: string } | null;
  ticket: string | null;
  questionsAmount: string | null;
  correctQuestions: number | null;
  stationsAmount: string | null;
  consumedAt: string | null;
  gameDuration: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  score: number | null;
  totalStations: number | null;
  completedStations:  number | null;
  incorrectAttempts: number | null;
  locationMistakes: number | null;
  currentStation: any | null;
  allowedToPublish: boolean | null;
  groupPhoto: any | null;
  status: string | null;
  assignedStations: string[] | null;
  point: number | null;
  startTime: string | null;
  endTime: string | null;
};

export type ResultForm = {
  id: FormElement;
  organizationName: FormElement;
  name: FormElement;
  mobileNumber: FormElement;
  email: FormElement;
  department: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  key: FormElement;
};

export type ResultsState = {
  list: Array<ResultsListItemType>;
  ticket?: Ticket;
  pagination: Pagination;
  loading: boolean;
  resultInfo: ResultAnswers;
  error: string;
  currentResult?: string
};

export const ResultsState: ResultsState = {
  list: [],
  resultInfo: {
    currentTab: 'gameDetails',
    results: [],
    loading: false,
  },
  ticket: undefined,
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 40,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  loading: false,
  error: "",
};

export interface ResultResponse {
  id: string;
  organizationName: string;
  name: string;
  mobileNumber: string;
  email: string;
  department: string;
  createdAt: string;
  updatedAt: string;
  key: string;
}

export interface ResultRequestState {
  firstName: string;
  lastName: string;
  email: string;
  user_type: string;
  dob: string;
}

export type ContainerState = ResultsState;

import i18next from "i18next";
import { formatTableTime, formatHumanizedDuration } from "styles/theme/utils";

export const TableHeaderDefinition = {
  NUM: {
    id: 'num',
    label: "NUM",
    format: (value, index) => index + 1, // Use index to generate a sequential number
    maxWidth: 80,
    minWidth: 80,
    sortable: false,
  },
  GROUP_NAME: {
    id: 'groupName',
    label: "GROUP_NAME",
    format: (value) => value.groupName || "", // Default to an empty string if no value
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  START_TIME: {
    id: 'startTime',
    label: "START_TIME",
    format: (value) => formatTableTime(value.startTime), // Format as table time
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  END_TIME: {
    id: 'endTime',
    label: "END_TIME",
    format: (value) => formatTableTime(value.endTime), // Format as table time
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  SCORE: {
    id: 'score',
    label: "SCORE",
    format: (value) => value.score || 0, // Default to 0 if no score
    maxWidth: 70,
    minWidth: 70,
    sortable: true,
  },
  CORRECT_QUESTIONS: {
    id: 'correctQuestions',
    label: "CORRECT_QUESTIONS",
    format: (value) => value.correctQuestions || 0, // Default to 0 if no value
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
  },
  MISTAKES: {
    id: 'incorrectAttempts',
    label: "MISTAKES",
    format: (value) => value.incorrectAttempts || 0, // Default to 0 if no value
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  LOCATION_MISTAKES: {
    id: 'locationMistakes',
    label: "LOCATION_MISTAKES",
    format: (value) => value.locationMistakes || 0, // Default to 0 if no value
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
  },
  STATIONS_DONE: {
    id: "stationsDone",
    label: "STATIONS_DONE",
    format: (row) => {return  i18next.t('RESULTS.MIN_OF_MAX', {min: row.completedStations, max: row.totalStations})},
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
  },
  QUESTIONS_DONE: {
   id: 'questionsDone',
    label: "QUESTIONS_DONE",
    format: (row) => {return  i18next.t('RESULTS.MIN_OF_MAX', {min: row.completedQuestions, max: row.totalQuestions})},
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
  },
  TOTAL_TIME: {
    id: 'totalTime',
    label: "TOTAL_TIME",
    format: (row) => formatHumanizedDuration(row.startTime, row.endTime), // Use formatHumanizedDuration
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
  STATUS: {
    id: 'status',
    label: "STATUS",
    format: (row) => row.currentStation?._id ? "In Progress" : "Done", // Display status based on currentStation
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  CURRENT_STATION: {
    id: 'currentStation',
    label: "CURRENT_STATION",
    format: (row) => row.currentStation?.name || "", // Display current station name if available
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  }
};

export const gridTemplateColumns = `${Object.values(TableHeaderDefinition)
  .map((column) => `${column.minWidth}px` || "1fr") // Use defined width or fallback to "1fr"
  .join(" ")} 100px`

export interface TableHeaderColumnType {
  id?: string; // Unique identifier for each column
  label: string; // The label to be displayed in the header
  format: (value: any, row?: any) => string | number; // A function to format the column value
  maxWidth: number; // Maximum width of the column
  minWidth: number; // Minimum width of the column
  sortable: boolean; // Whether the column is sortable or not
}

export const TableHeaders: TableHeaderColumnType[] = Object.values(TableHeaderDefinition);

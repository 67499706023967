import { Box } from "@mui/material";
import React from "react";
import logo from "assets/logo.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dateFormatter } from "utils/helpers";
import {
  selectCurrentTab,
  selectTicket,
} from "app/containers/Results/redux/selector";
import MobileStack from "../MobileStack";

type Props = {
  isMobile: boolean;
};

export default function GameDetails({ isMobile }: Props) {
  const { t } = useTranslation();
  const ticket = useSelector(selectTicket);
  const currentTab = useSelector(selectCurrentTab);
  const handleCopy = () => {
    if (navigator?.clipboard) {
      const currentURL = window.location.href;

      navigator.clipboard.writeText(currentURL).then(
        function () {
          console.log("Text successfully copied to clipboard");
        },
        function (err) {
          console.error("Could not copy text: ", err);
        }
      );
    }
  };
  if (!isMobile || currentTab !== "gameDetails") {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "25vh",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {ticket?.game?.startGameAsset?.url ? (
          <>
            {ticket?.game?.startGameAsset?.type === "video" ? (
              <video
                style={{ height: '300px',  objectFit: "contain", maxWidth: '100%',
                  maxHeight: '100%', }}
                controls
                src={ticket?.game?.startGameAsset?.url}
              />
            ) : (
              <img
                style={{ height: '300px', objectFit: "contain", maxWidth: '100%',
                  maxHeight: '100%', }}
                src={ticket?.game?.startGameAsset?.url}
                alt="game"
              />
            )}
          </>
        ) : null}
      </Box>
      <Box sx={{ padding: "0px 8px 30px 8px" }}>
        {ticket?.customer?.name && (
          <MobileStack
            label={"CUSTOMER_NAME"}
            value={ticket?.customer?.name || ""}
          />
        )}

        <MobileStack label={"GAME_NAME"} value={ticket?.game?.name || ""} />

        <MobileStack
          label={"FROM_DATE"}
          value={dateFormatter(ticket?.startDate)}
        />

        <MobileStack label={"TO_DATE"} value={dateFormatter(ticket?.endDate)} />

        <MobileStack
          label={"CREATED_DATE"}
          value={dateFormatter(ticket?.createdAt)}
        />

        <MobileStack
          label={"NUMBER_OF_DEVICES"}
          value={String(ticket?.noOfDevices) || ""}
        />
      </Box>
    </>
  );
}

export interface FormElement {
  value: any;
  error?: string;
}
export interface FormError {
  name: string;
  error: string;
}

export interface SignedURLResponse {
  key: string;
  previewUrl: string;
  url: string;
}
export interface FileURLResponse {
  file: string;
  fileName: string;
  fileSize: string;
  id: string;
  mime: string;
  type: string;
  url: string;
}
export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export interface Pagination {
  filters?: {
    isActive: string;
  };
  search: string;
  availableSearch?: string[];
  page: number;
  perPage?: number;
  orderBy: string;
  orderDirection: OrderDirections;
  availableOrderBy?: OrderDirections[];
  availableOrderDirection?: string[];
  total: number;
  totalPage: number;
}

export interface Query {
  organizationId ?: string;
  organization ?: string;
  city ?: string;
  user ?: string;
  creator?:string;
  type?: string;
  search?: string;
  page?: number;
  perPage?: number;
  orderBy?: string;
  orderDirection?: OrderDirections;
  isActive?: string;
  stationId?: string;
  isCustomQuestion?:string
  
}

export type QueryWithCallback = Query & {callback?(params: any): void}

export enum OrderDirections {
  ASC = "asc",
  DESC = "desc",
}

export interface Metadata {
  languages: string[];
  timestamp: number;
  timezone: string;
  requestId: string;
  path: string;
  version: string;
  repoVersion: string;
  pagination: Pagination;
}
export interface ListItem {
  id: string;
  name: string;
  icon: any;
  isCheckBox?:boolean;
  onClick: () => void;
}

export enum MediaTypes {
  IMAGE = "image",
  VIDEO = "video",
  PDF = "pdf",
  IMAGE_VIDEO = "image/video",
  NONE = "none",
}
import {
  FormElement,
  Metadata,
  OrderDirections,
  Pagination,
} from "app/containers/types";
import { CreateAssetResponse } from "app/components/DragNDropDialog/types";
import { Organization } from "app/containers/Organizations/types";
import { Station } from "app/containers/Stations/types";
import { User } from "app/types";

export type GameForm = {
  _id: FormElement;
  name: FormElement;
  emailLanguage:  FormElement;
  backgroundId: FormElement;
  organizationId: FormElement;
  gameBannerId: FormElement;
  backgroundUrl: FormElement;
  gameBannerUrl: FormElement;
  groupInstructionText: FormElement;
  groupInstructionPDF: FormElement;
  startGameText: FormElement;
  startGameAssetId: FormElement;
  endGameAssetId: FormElement;
  startGameAssetUrl: FormElement;
  endGameAssetUrl: FormElement;
  endGameText: FormElement;
  course: FormElement;
  showStartPoint: FormElement;
  showEndPoint: FormElement;
  startIsIdentical: FormElement;
  endIsIdentical: FormElement;
  isActive: FormElement;
  canSkip: FormElement;
  canSkipAfter: FormElement;
  numberOfMistakesTillReveal: FormElement;
  delayAfterMistake: FormElement;
  numberOfMistakesTillDelay: FormElement;
  groupInstructionPDFId: FormElement;
  startGameAssetType: FormElement;
  endGameAssetType: FormElement;
  defaultTicket: {
    testPassword: string;
    isOpen: boolean;
  };
};

export type GamesState = {
  form: GameForm;
  games: Game[];
  isLoading: boolean;
  pagination: Pagination;
  stations: Station[];
  selectedFullStations: Station[];
  selectedStations: SelectedStation[];
  isLoadingStations: boolean;
  stationsPagination: Pagination;
  isUnsaved: boolean;
};

export interface SelectedStation {
  id: string;
  number: number;
  station: { _id: string; name: string };
}

export enum CourseTypes {
  IDENTICAL = "identical_for_all",
  RANDOM_STATION = "random_stations",
  RANDOM_STATION_GROUPS = "random_station_group",
}

export const InitialGamesState: GamesState = {
  games: [],
  form: {
    defaultTicket: {
      isOpen: false,
      testPassword: "",
    },
    emailLanguage: {
      value: "en",
      error: "",
    },
    _id: {
      value: "",
      error: "",
    },
    organizationId: {
      value: "",
      error: "",
    },
    name: {
      value: "",
      error: "",
    },
    backgroundId: {
      value: "",
      error: "",
    },
    gameBannerId: {
      value: "",
      error: "",
    },
    backgroundUrl: {
      value: "",
      error: "",
    },
    gameBannerUrl: {
      value: "",
      error: "",
    },
    groupInstructionText: {
      value: "",
      error: "",
    },
    groupInstructionPDF: {
      value: "",
      error: "",
    },
    groupInstructionPDFId: {
      value: "",
      error: "",
    },
    startGameText: {
      value: "",
      error: "",
    },
    endGameText: {
      value: "",
      error: "",
    },
    showStartPoint: {
      value: true,
      error: "",
    },
    showEndPoint: {
      value: true,
      error: "",
    },
    course: {
      value: CourseTypes.IDENTICAL,
      error: "",
    },
    startIsIdentical: {
      value: false,
      error: "",
    },
    endIsIdentical: {
      value: false,
      error: "",
    },
    isActive: {
      value: true,
      error: "",
    },
    startGameAssetId: {
      value: "",
      error: "",
    },
    endGameAssetId: {
      value: "",
      error: "",
    },
    startGameAssetUrl: {
      value: "",
      error: "",
    },
    endGameAssetUrl: {
      value: "",
      error: "",
    },
    canSkip: {
      value: false,
      error: "",
    },
    canSkipAfter: {
      value: 3,
      error: "",
    },
    numberOfMistakesTillReveal: {
      value: 3,
      error: "",
    },
    delayAfterMistake: {
      value: 3,
      error: "",
    },
    numberOfMistakesTillDelay: {
      value: 3,
      error: "",
    },
    startGameAssetType: {
      value: "",
      error: "",
    },
    endGameAssetType: {
      value: "",
      error: "",
    },
  },
  isLoading: false,
  stations: [],
  selectedStations: [],
  selectedFullStations: [],
  isLoadingStations: false,
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 40,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  stationsPagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 40,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  isUnsaved: false,
};

interface WelcomeScreenSettings {
  showStartPoint: boolean;
  showEndPoint: boolean;
}

interface CourseSettings {
  course: string;
  startIsIdentical: boolean;
  endIsIdentical: boolean;
}
export interface DefaultTicket {
  testPassword: string;
}
export interface Game {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  organization: Organization | string;
  name: string;
  emailLanguage: 'en' | 'he';
  background: CreateAssetResponse;
  gameBanner: CreateAssetResponse;
  groupInstructionText: string;
  groupInstructionPDF: CreateAssetResponse;
  startGameText: string;
  creator?: User;
  endGameText: string;
  startGameAsset: CreateAssetResponse;
  endGameAsset: CreateAssetResponse;
  welcomeScreenSettings: WelcomeScreenSettings;
  courseSettings: CourseSettings;
  gameStations:
    | StationData[]
    | {
        station: string;
        group: number;
        sortingOrder: number;
      }[];
  gameSettings: GameSettings;
  noOfStations?: number;
  isActive: boolean;
  defaultTicket?: DefaultTicket;
}

export interface GameSettings {
  canSkip: boolean;
  canSkipAfter: number;
  numberOfMistakesTillReveal: number;
  delayAfterMistake: number;
  numberOfMistakesTillDelay: number;
}

interface StationList {
  _id: string;
  name: string;
  noOfQuestionss: number;
}

interface StationData {
  _id: string;
  createdAt: string;
  updatedAt: string;
  station: StationList;
  group: string;
  sortingOrder: number;
}

export interface GameResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Game[];
}

export type ContainerState = GamesState;

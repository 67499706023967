import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "assets/logo.png";
export default function Header() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        height: "70px",
        width: "100%",
        padding: "16px",
        backgroundColor: "#26A8E0",
        borderRadius: "0px 0px 32px 32px",
        position: "absolute",
        zIndex: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "21px",
          letterSpacing: "0em",
          color: "#FFFFFF",
        }}
      >
        {t("TICKETS.CUSTOMIZED_TICKETS.TICKET_CUSTOMIZATIONS")}
      </Typography>
      <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems:'flex-end' , flex:1 , flexGrow: 1, flexDirection: 'row'}}>
        <img src={logo} width="100" height="" alt="logo" />
      </Box>
    </Box>
  );
}

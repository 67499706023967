import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Station } from "app/containers/Stations/types";
import { formatUUID } from "styles/theme/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";
import { useSelector } from "react-redux";

interface StationTileProps {
  readonly isEven?: boolean;
  readonly disabled?: boolean;
  readonly station?: Station;
  readonly handleBackClick?: () => void;
}

export default function StationTile({
  isEven,
  disabled,
  station,
  handleBackClick,
}: StationTileProps) {
  const {i18n} = useTranslation()
  const direction = useSelector(selectDirection);
  return (
    <Box
      sx={{
        marginTop: "6px",
        width: "100%",
        height: "52px",
        padding: "14px 10px",
        borderRadius: "16px",
        border: "1px solid #86BDE3",
        gap: "10px",
        angle: "-180 deg",
        "&:nth-of-type(odd)": {
          backgroundColor: "#F3F6F8",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#ffffff",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingRight: "30px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            width: "30%",
          }}
        >
          <Box
            sx={{
              width: "10%",
     
            }}
          >
            <IconButton
              disabled={disabled}
              sx={{
                width: "20px",
                height: "20px",
                padding: "0px",
                backgroundColor: "#E24399",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:disabled": {
                  backgroundColor: "#D7D7E0",
                },
              }}
              onClick={handleBackClick}
            >
              {
                 direction === "rtl" ? (
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: "16px",
                      color: "#ffffff",
                      transform: "rotate(180deg)"
                    }}
                  />
                ) : (
                  <KeyboardArrowLeftIcon
                    sx={{
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  />
                )
              }
            </IconButton>
          </Box>
          <Link
           target="_blank"
            to={`/app/stations/${station?._id}/view`}
            style={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0em",
              color: "#2F80ED",
              cursor: 'pointer',
              marginLeft: '15px',
              marginRight: '15px',
    
            }}
          >
            {formatUUID(station?._id) || 'N/A'}
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "10px",
            width: "50%",

          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#3C3C43",
              textAlign: "center",
              
            }}
          >
            {station?.name}
          </Typography>
        </Box>
              <Box
                sx={{
                  width: "20%",
                  justifyContent: 'flex-end',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Link
                  target="_blank"
                  to={`/app/editors/${station?.creator?._id}/view`}
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    color: "#2F80ED",
                    cursor: "pointer",
                  }}
                >
                  {station?.creator?.fullName || "N/A"}
                </Link>
              </Box>
      </Box>
    </Box>
  );
}

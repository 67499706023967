import { Editor } from "app/containers/Editors/types";
import { OrderDirections, Pagination } from "app/containers/types";

export type EditorSelectState = {
  editors: Editor[];
  pagination: Pagination;
  isLoading: boolean;
};

export const EditorSelectState: EditorSelectState = {
  editors: [],
  isLoading: false,
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 40,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  }
};

export type ContainerState = EditorSelectState;
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { selectSelectedStations } from 'app/containers/Games/redux/selector'
import { useDispatch, useSelector } from 'react-redux'
import { useInjectReducer, useInjectSaga } from 'redux-injectors'
import { gameQuestionsSlice, gamesQuestionsReducer } from './redux/slice'
import { gamesQuestionsSaga } from './redux/saga'
import StationAndQuestions from '../StationAndQuestions'

export default function GamesStations({
    disabled
}:{
    readonly disabled?: boolean
}) {
    useInjectReducer({ key: gameQuestionsSlice, reducer: gamesQuestionsReducer });
    useInjectSaga({ key: gameQuestionsSlice, saga: gamesQuestionsSaga });
    const { t } = useTranslation()
    const selectedStations = useSelector(selectSelectedStations)

    return (
        <Box maxHeight={1200}  overflow='auto'>
            <Typography
                style={{
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "100%",
                    color: "#5E6781",
                    marginBottom: "24px",
                }}
            >
                {t("GAMES.FORM.GAMES_STATIONS")}
            </Typography>
            <Box 
                display={'flex'} 
                flexDirection={'row'}
                marginLeft={'40px'}
            >
                <Box
                    sx={{
                        width: "30%",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "100%",
                            color: "#5E6781",
                            marginBottom: "16px",
                            paddingTop: "16px",
                            paddingLeft: "16px",
                        }}
                    >
                        {t("STATIONS.FORM.LABELS.STATION_ID")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: "50%",
                    }}>
                    <Typography
                        style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "100%",
                            color: "#5E6781",
                            marginBottom: "16px",
                            paddingTop: "16px",
                        }}
                    >
                        {t("STATIONS.FORM.LABELS.STATION_NAME")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: "20%",
                    }}>
                    <Typography
                        style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "100%",
                            color: "#5E6781",
                            marginBottom: "16px",
                            paddingTop: "16px"
                        }}
                    >
                        {t("STATIONS.FORM.LABELS.GROUP_NUMBER")}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}
            >
                {
                    selectedStations.length < 1 ? (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "100%",
                                color: "#5E6781",
                                
                                textAlign: "center",
                                my: '40px'
                            }}
                        >
                            {t("STATIONS.FORM.NO_STATION_ADDED_YET")}
                        </Typography>
                    ) : (
                        selectedStations.map((gS, i) => (
                            <StationAndQuestions
                                key={gS.station._id}
                                i={i}
                                station={gS}
                                disabled={disabled}
                            />
                        ))
                    )
                
                }
            </Box>
        </Box>
    )
}

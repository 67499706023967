import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gridTemplateColumns, TableHeaderDefinition } from "./headers";
import { LineButton } from "../LineButton";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

export const TableRow = ({ row, index, onClick, isMobile }) => {
  const { t } = useTranslation();
  // Function to render value based on column configuration
  const renderCell = (column, row, index) => {
    return column.format(row, index);
  };
    const { width } = useWindowDimensions();

  // Mobile View: Card Layout
  if (isMobile) {
    return (
      <Box
        sx={{
          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
          padding: "12px",
          borderRadius: "8px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "12px",
          width: width - 30,
        }}
      >
        <Grid container spacing={1} sx={{ textAlign: "left" }}>
          {Object.values(TableHeaderDefinition).map((column, i) => (
            <Grid item xs={6} key={i}>
              <Typography variant="body2" noWrap>
                {t(`TABLES.${column.label}`)}: {renderCell(column, row, index)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <LineButton onClick={onClick}>{t("RESULTS.LABELS.SEE_ALL")}</LineButton>
      </Box>
    );
  }

  // Desktop View: Table Row
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: gridTemplateColumns, // Dynamically set grid columns based on number of headers
        fontWeight: "bold",
        backgroundColor: "#f0f0f0",
        padding: "10px",
        borderRadius: "8px",
        textAlign: "center",
        marginBottom: "8px",
      }}
    >
      {Object.values(TableHeaderDefinition).map((column, i) => {
        return <Typography noWrap>{renderCell(column, row, index)}</Typography>;
      })}

      <LineButton onClick={onClick}>{t("RESULTS.LABELS.SEE_ALL")}</LineButton>
    </Box>
  );
};

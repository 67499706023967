import { Box } from "@mui/material";
import { selectCustomizeTicket } from "app/containers/Tickets/redux/selector";
import React from "react";
import { useSelector } from "react-redux";
import twigoLogo from "assets/logo1.png";

export default function Image() {
  const ticket = useSelector(selectCustomizeTicket);
  const isVideo = ticket.ticket?.game?.startGameAsset?.type === "video";
  return (
    <>
      {ticket.ticket?.game?.startGameAsset?.url ? (
        <Box
          sx={{
            height: "250px",
            width: "100%",
            marginBottom: "16px",
            marginTop: "70px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {isVideo ? (
            <video
              style={{
                marginTop: '10px',
                objectFit: "contain",
                width: '100%', 
              }}
              controls
              src={ticket.ticket?.game?.startGameAsset?.url}
            />
          ) : (
            <img
              alt="customization"
              src={ticket.ticket?.game?.startGameAsset?.thumbUrl}
              width={"100%"}
              height={"100%"}
              style={{ marginTop: '10px', width: '100%',  objectFit: "contain" }}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
            marginTop: "100px",
          }}
        >
          <img width="200px" height="88.61px" src={twigoLogo} />
        </Box>
      )}
    </>
  );
}

import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Customer, CustomersState } from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";

const initialState = CustomersState;

export const formSlice = createSlice({
  name: "customersState",
  initialState,
  reducers: {
    setFormValue(state, action: PayloadAction<{ key: string; value: any }>) {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.isUnsaved = true;
    },
    setFormErrors(
      state,
      action: PayloadAction<FormError[]>
    ) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    createCustomer: (state, action: PayloadAction<{customer: Customer, callback?: ()=>void;}>) => {
      state.isLoading = true;
    },
    createCustomerSuccess: (state) => {
      state.isUnsaved = false;
      state.isLoading = false;
    },
    createCustomerError: (state) => {
      state.isLoading = false;
    },
    getCustomers: (state, action: PayloadAction<Query>) => {
      state.isLoading = true;
    },
    getCustomersSuccess: (state, action: PayloadAction<{ customers: Customer[], pagination: Pagination }>) => {
      state.customers = action.payload.pagination.page === 1 ? action.payload.customers : [
        ...state.customers,
        ...action.payload.customers,
      ];
      state.pagination = action.payload.pagination;
      state.isLoading = false;
    },
    getCustomersError: (state) => {
      state.isLoading = false;
    },
    getCustomer: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.form._id.value = action.payload._id;
      state.form.organizationId.value = action.payload.organization;
      state.form.name.value = action.payload.name;
      state.form.companyName.value = action.payload.companyName;
      state.form.taxNumber.value = action.payload.taxNumber;
      state.form.email.value = action.payload.email;
      state.form.address.value = action.payload.address?.street;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.noOfPlayedGames.value = action.payload.noOfPlayedGames;
      state.form.numberOfPurchasedGames.value = action.payload.numberOfPurchasedGames;
      state.form.createdAt.value = action.payload.createdAt;
      state.form.type.value = action.payload.type;
      state.isLoading = false;
      state.isUnsaved=false;
    },
    getCustomerError: (state) => {
      state.isLoading = false;
    },
    updateCustomer: (state, action: PayloadAction<{customer: Customer, callback?: ()=>void;}>) => {
      state.isLoading = true;
    },
    updateCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.form._id.value = action.payload._id;
      state.form.organizationId.value = action.payload.organization;
      state.form.name.value = action.payload.name;
      state.form.companyName.value = action.payload.companyName;
      state.form.taxNumber.value = action.payload.taxNumber;
      state.form.email.value = action.payload.email;
      state.form.address.value = action.payload.address?.street;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.noOfPlayedGames.value = action.payload.noOfPlayedGames;
      state.form.numberOfPurchasedGames.value = action.payload.numberOfPurchasedGames;
      state.form.createdAt.value = action.payload.createdAt;
      state.form.type.value = action.payload.type;
      state.isLoading = false;
      state.isUnsaved=false;
    },
    updateCustomerError: (state) => {
      state.isLoading = false;
    },
    deleteCustomer: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    deleteCustomerSuccess: (state) => {
      state.isLoading = false;
    },
    deleteCustomerError: (state) => {
      state.isLoading = false;
    },
    resetForm: (state, action: PayloadAction<{ callback?: () => void }>) => {
      state.isLoading = false;
      state.form = initialState.form;
      state.isUnsaved=false
    },
  },
});

export const { reducer: customersReducer, actions, name: customersSlice } = formSlice;

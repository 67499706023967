import { TextField, Typography } from '@mui/material'
import React from 'react'
import { Search } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectDirection } from 'styles/theme/slice';
import { useSelector } from 'react-redux';

export default function SearchInput({
    disabled,
    provisionLabel=false
}:{
    readonly disabled?: boolean
    readonly provisionLabel?: boolean
}) {
    const { t } = useTranslation();
  const direction = useSelector(selectDirection);
    const navigate = useNavigate();
    const searchParams  = useSearchParams();
    const q = searchParams?.[0]?.get("q");

    const setSearchQuery = (search: string) => {
        const params = new URLSearchParams(window.location.search);
        params.set('q', search);
        navigate(`?${params.toString()}`);
    }
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setSearchQuery(search);
    }
    return (
        <>
            
            {
                provisionLabel && (
                <Typography
                    sx={{
                    
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    visibility: "hidden",
                    }}
                >
                    search
                </Typography>
                )
            }
            <TextField
                sx={{
                    
                    minWidth: "100%",
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                        margin: 0,
                        background: "#F3F6F8",
                        height: "52px",
                        '& fieldset': {
                            border: 'none', // Remove the border
                        },
                    },
                    input: {
                        '&::placeholder': {
                            
                            fontSize: '14px',
                        },
                        unicodeBidi: 'plaintext',
                        fontSize: '14px',
                    }
                }}
                placeholder={t("COMMON.PLACEHOLDERS.SEARCH")}
                size="small"
                InputProps={{
                    startAdornment: <Search sx={{ color: "#727F89", marginRight: "10px" }} />,
                }}
                defaultValue={q}
                onChange={onChange}
                disabled={disabled}
            />
        </>
    )
}

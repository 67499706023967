import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import MessageCard from "../MessageCard";
import MediaUploader from "app/components/MediaUploader";
import { useTranslation } from "react-i18next";
import { selectGame } from "app/containers/Games/redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Games/redux/slice";
import { MediaTypes } from "app/containers/types";
import { selectDirection } from "styles/theme/slice";
import { ASSET_TYPE } from "utils/constants";

export default function StartGame({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const game = useSelector(selectGame);
  const dispatch = useDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setFormValues({ key: e.target.name, value: e.target.value })
    );
  };
  
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          
          fontSize: "14px",
          fontWeight: 600,
          letterSpacing: "0em",
          color: "#E24399",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        {t("GAMES.FORM.START_GAME")}
        <Tooltip title={t("GAMES.FORM.LABELS.START_GAME_TOOLTIP")}>

          <InfoIcon
            sx={{
              fontSize: "18px",
              marginLeft: "6px",
              color: "#3C3C43",
            }}
          />
        </Tooltip>
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "12px",
        }}
      >
        <MessageCard
          disabled={disabled}
          label={t("GAMES.FORM.START_MESSAGE")}
          value={game.startGameText.value}
          onChange={onChange}
          error={game.startGameText.error ? t(game.startGameText.error) : ""}
          name="startGameText"
          maxLength={512}
        />
        <MediaUploader
          onCheck={(value:MediaTypes) => {
            dispatch(actions.setFormValues({ key: "startGameAssetType", value }));
            dispatch(actions.setFormValues({ key: "startGameAssetUrl", value: "" }));
          }}
          showBtn={false}
          type={game?.startGameAssetType?.value || MediaTypes.NONE}
          disabled={disabled}
          primaryColor="#E24399"
          secondaryColor="#FBE4F0"
          caption={game?.startGameAssetType?.value === MediaTypes.VIDEO ? t("GAMES.FORM.LABELS.GAME_VIDEO_CAPTION") : t("GAMES.FORM.LABELS.GAME_IMAGE_CAPTION")}
          title={game?.startGameAssetType?.value === MediaTypes.VIDEO ? t("GAMES.FORM.LABELS.START_GAME_VIDEO") : t("GAMES.FORM.LABELS.START_GAME_IMAGE")}
          checked={game?.startGameAssetType?.value || ASSET_TYPE.NONE}
          src={game.startGameAssetUrl.value}
          error={
            game.startGameAssetUrl.error ? t(game.startGameAssetUrl.error) : ""
          }
          onSuccess={(img) => {
            dispatch(
              actions.setFormValues({
                key: "startGameAssetUrl",
                value: img?.url,
              })
            );
            dispatch(
              actions.setFormValues({
                key: "startGameAssetId",
                value: img?._id,
              })
            );
          }}
          fallbackWidth="354px"
          fallbackHeight="185px"
          height="185px"
          width="354px"
    
        />
      </Box>
    </Box>
  );
}

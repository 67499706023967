import React, { useEffect, useState } from "react";
import { Typography, Grid, IconButton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Thumbnail from "./components/Thumbnail";
import MediaModal from "./components/MediaModal"; // Import the MediaModal
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectCurrentTab,
  selectResultInfo,
} from "app/containers/Results/redux/selector";
import { actions } from "../../../../redux/slice";

export default function GroupPhotos({ isMobile }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentTab = useSelector(selectCurrentTab);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    url: string;
    type: string;
    name?: string;
  }>({ url: "", type: "image", name: "" });

  useEffect(() => {
    if (!id) return;
    dispatch(actions.getTicketResultInfo(id));
  }, [id]);

  const resultInfo = useSelector(selectResultInfo);
  if (!resultInfo.length) {
    return null;
  }

  const handleThumbnailClick = (
    url: string,
    type: "image" | "video" | "text",
    name?: string
  ) => {
    setModalContent({ url, type, name });
    setModalOpen(true);
  };

  if (isMobile && currentTab !== "photos") {
    return null;
  }

  return (
    <Grid2
      container
      spacing={2}
      sx={{
        padding: "16px",
        borderRadius: "12px",
        background: "#FFFFFF",
        marginTop: "16px",
        marginBottom: "40px",
        marginX: "12px",
      }}
    >
      <Grid2 md={12}>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "32px",
            letterSpacing: "0em",
            color: "#207DB9",
          }}
        >
          {t("RESULTS.PHOTOS_BY_CREATIVE")}
        </Typography>
      </Grid2>
      <Grid2
        md={12}
        sx={{
          marginX: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {resultInfo.map((result, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              marginBottom: "24px",
              padding: "0 15px",
            }}
          >
            <Typography sx={{ marginBottom: "8px" }}>
              {result.groupName}
            </Typography>
            <Grid container spacing={2}>
              {result.stationResults?.map((m, mIndex) =>
                m?.data?.map((answer, aIndex) => {
                  const asset = answer?.answer?.asset;
                  const url = asset?.url || "";
                  const isVideo = url?.match(/\.(mp4|webm|ogg|mov)$/i);
                  const isText = !isVideo && !url?.match(/\.(jpg|jpeg|png|gif)$/i);

                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={`${mIndex}-${aIndex}`} sx={{paddingLeft: 0}}>
                      <Thumbnail
                        url={url}
                        thumbUrl={answer?.answer?.asset?.thumbUrl}
                        name={answer?.answer?.comment}
                        onClick={() =>
                          handleThumbnailClick(
                            url,
                            isVideo ? "video" : isText ? "text" : "image",
                            isText ? answer?.answer?.comment : undefined
                          )
                        }
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </div>
        ))}
      </Grid2>

      <MediaModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        url={modalContent.url}
        type={modalContent.type as "image" | "video" | "text"}
        name={modalContent.name}
      />
    </Grid2>
  );
}
